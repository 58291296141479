import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
/**
 * TODO Sven/Morten: Should be either generated from auth0 endpoint "permissions" into a .d.ts file with a hook in codegen.ts or introduced as an enum from backend
 */
export var PERMISSIONS = {
  ESTAID: "estaid",
  FILE_CREATE_ORG: "file:create:org",
  FILE_DELETE_ORG: "file:delete:org",
  FILE_READ_ORG: "file:read:org",
  FILE_UPDATE_ORG: "file:update:org",
  LIST_CREATE_ORG: "list:create:org",
  LIST_DELETE_ORG: "list:delete:org",
  LIST_READ_ORG: "list:read:org",
  LIST_UPDATE_ORG: "list:update:org",
  LOKALE_BASEN_READ_ORG: "lokale_basen:read:org",
  NOTE_CREATE_ORG: "note:create:org",
  NOTE_DELETE_ORG: "note:delete:org",
  NOTE_READ_ORG: "note:read:org",
  NOTE_UPDATE_ORG: "note:update:org",
  ORGANIZATION_READ_ALL: "organization:read:all",
  ORGANIZATION_READ_OWN: "organization:read:own",
  PORTFOLIO_BUSINESS_PLANS_CREATE_ORG: "portfolio_business_plans:create:org",
  PORTFOLIO_BUSINESS_PLANS_DELETE_ORG: "portfolio_business_plans:delete:org",
  PORTFOLIO_BUSINESS_PLANS_READ_ORG: "portfolio_business_plans:read:org",
  PORTFOLIO_BUSINESS_PLANS_UPDATE_ORG: "portfolio_business_plans:update:org",
  PORTFOLIO_CREATE_ORG: "portfolio:create:org",
  PORTFOLIO_DELETE_ORG: "portfolio:delete:org",
  PORTFOLIO_READ_ORG: "portfolio:read:org",
  PORTFOLIO_SALES_READ_ORG: "portfolio_sales:read:org",
  PORTFOIO_SALES_CREATE_ORG: "portfolio_sales:create:org",
  PORTFOLIO_SALES_UPDATE_ORG: "portfolio_sales:update:org",
  PORTFOLIO_SALES_DELETE_ORG: "portfolio_sales:delete:org",
  PORTFOLIO_VALUATIONS_READ_ORG: "portfolio_valuations:read:org",
  PORTFOLIO_VALUATIONS_CREATE_ORG: "portfolio_valuations:create:org",
  PORTFOLIO_VALUATIONS_UPDATE_ORG: "portfolio_valuations:update:org",
  PORTFOLIO_VALUATIONS_DELETE_ORG: "portfolio_valuations:delete:org",
  RENT_REQUEST_READ_ORG: "rent_request:read:org",
  RENT_REQUEST_WRITE_ORG: "rent_request:write:org",
  SUPERSET_ADMIN: "superset:admin",
  TEMPLATE_BUILDER_READ_ORG: "template_builder:read:org"
};
export var useHasPermissions = function useHasPermissions() {
  for (var _len = arguments.length, testPermissions = new Array(_len), _key = 0; _key < _len; _key++) {
    testPermissions[_key] = arguments[_key];
  }
  var me = useMe();
  var result = computed(function () {
    return me.loading.value ? false : testPermissions.every(function (permission) {
      var _me$result$value;
      return (_me$result$value = me.result.value) === null || _me$result$value === void 0 ? void 0 : _me$result$value.me.permissions.includes(permission);
    });
  });
  return {
    loading: me.loading,
    result: result
  };
};