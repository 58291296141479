import "core-js/modules/es7.array.flat-map.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import { BalanceTableContextInjectionKey, BudgetCategoryMapInjectionKey, getValuesFlat, BalancePreferencesInjectionKey } from "~/helpers/portfolio/portfolio-balance-helpers";
export var useBalanceRowValues = function useBalanceRowValues(values) {
  var budgetRecordsMap = useInject(BudgetCategoryMapInjectionKey);
  var context = useInject(BalanceTableContextInjectionKey, true);
  var preferences = useInject(BalancePreferencesInjectionKey, true);
  var currentPeriod = useBalanceCurrentPeriod(computed(function () {
    return preferences.value.periodType;
  }));
  return computed(function () {
    var _budgetRecordsMap$val;
    return getValuesFlat(values, context.value.columns, currentPeriod.value, (_budgetRecordsMap$val = budgetRecordsMap === null || budgetRecordsMap === void 0 ? void 0 : budgetRecordsMap.value) !== null && _budgetRecordsMap$val !== void 0 ? _budgetRecordsMap$val : new Map(), preferences.value).flatMap(function (v) {
      return v.values;
    });
  });
};