import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.regexp.search.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.regexp.split.js";
import { PlannedRentsMetricsCategory, CustomFieldsDocument } from "~/graphql/generated/graphql";
import { daysOnMarketValues, DaysOnMarketFilter, vacantUnitFilter, rerentFilter, tenanciesSearchFilter, tenancyTypeFilter, portfolioIdsFilter, propertyIdsFilter, areaRangeFilter, zipRangeFilter, arrearsFilter, irrevocableFilter, assignedToFilter, rentRegulationFilter, excludeTenancyFromResultsFilter, customFieldFilter, tenancyAdminStartFilter, tenancyAdminEndFilter, propertyAdminStartFilter, propertyAdminEndFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { hasValue } from "~/helpers/common-helpers";
import { useQuery } from "@vue/apollo-composable";
export var useTenanciesQueryVariables = function useTenanciesQueryVariables(variables) {
  var withSelectedDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var _useTenanciesUrlParam = useTenanciesUrlParamRefs(),
    daysOnMarket = _useTenanciesUrlParam.daysOnMarket,
    rentStatus = _useTenanciesUrlParam.rentStatus,
    salesStatus = _useTenanciesUrlParam.salesStatus,
    search = _useTenanciesUrlParam.search,
    tenancyTypes = _useTenanciesUrlParam.tenancyTypes,
    rentRegulationPrinciples = _useTenanciesUrlParam.rentRegulationPrinciples;
  var params = useTenanciesUrlParams();
  var customParams = useCustomUrlParams();
  var customFieldsQuery = useQuery(CustomFieldsDocument);
  var customFields = computed(function () {
    var _customFieldsQuery$re;
    return (_customFieldsQuery$re = customFieldsQuery.result.value) === null || _customFieldsQuery$re === void 0 ? void 0 : _customFieldsQuery$re.assetManagementCustomFields.items;
  });
  var customFieldsLoading = computed(function () {
    return customFieldsQuery.loading.value;
  });
  var _params = params("arrears"),
    arrears = _params.value;
  var _params2 = params("startFrom"),
    startFrom = _params2.value;
  var _params3 = params("startTo"),
    startTo = _params3.value;
  var _params4 = params("endFrom"),
    endFrom = _params4.value;
  var _params5 = params("endTo"),
    endTo = _params5.value;
  var _params6 = params("propertyAdminStartFrom"),
    propertyAdminStartFrom = _params6.value;
  var _params7 = params("propertyAdminStartTo"),
    propertyAdminStartTo = _params7.value;
  var _params8 = params("propertyAdminEndFrom"),
    propertyAdminEndFrom = _params8.value;
  var _params9 = params("propertyAdminEndTo"),
    propertyAdminEndTo = _params9.value;
  var _params10 = params("irrevocableTenant"),
    irrTenant = _params10.value;
  var _params11 = params("irrevocableLandlord"),
    irrLandlord = _params11.value;
  var _params12 = params("areaFrom"),
    areaFrom = _params12.value;
  var _params13 = params("areaTo"),
    areaTo = _params13.value;
  var _params14 = params("zipFrom"),
    zipFrom = _params14.value;
  var _params15 = params("zipTo"),
    zipTo = _params15.value;
  var _params16 = params("assignedTo"),
    assignedTo = _params16.value;
  var _params17 = params("date"),
    date = _params17.value;
  var selectedDate = computed(function () {
    return withSelectedDate ? date.value : undefined;
  });
  var propertyIds = useTenanciesPropertyIds(variables.value.propertyId);
  var portfolioIds = useTenanciesPortfolioIds(variables.value.portfolioId);
  var _useUrlParamSort = useUrlParamSort("sort"),
    sort = _useUrlParamSort.value;
  var _useUrlParamNumber = useUrlParamNumber("page"),
    page = _useUrlParamNumber.value;
  var _useUrlParamNumber2 = useUrlParamNumber("pageSize", 25),
    pageSize = _useUrlParamNumber2.value;
  var pagination = computed(function () {
    return {
      page: page.value,
      pageSize: pageSize.value
    };
  });
  var _useQueryFilters = useQueryFilters(),
    queryFilters = _useQueryFilters.value,
    setQueryFilters = _useQueryFilters.setValue;
  var getDaysOnMarketFilterById = function getDaysOnMarketFilterById(id) {
    return daysOnMarketValues.find(function (item) {
      return item.id == id;
    }) || daysOnMarketValues[0];
  };
  watch([customParams, customFields], function () {
    customParams.value.forEach(function (_ref) {
      var _customFields$value;
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      /** Note Sven: Not the same format as custom_{id}_{name}, so not using parseCustomFieldKey. In the url it is c_{id} to shorten it a bit */
      var _key$split = key.split("_"),
        _key$split2 = _slicedToArray(_key$split, 2),
        id = _key$split2[1];
      var field = (_customFields$value = customFields.value) === null || _customFields$value === void 0 ? void 0 : _customFields$value.find(function (f) {
        return f.id === id;
      });
      setQueryFilters("filters", function () {
        var _value$value$value;
        return customFieldFilter(field, (_value$value$value = value.value.value) !== null && _value$value$value !== void 0 ? _value$value$value : "");
      }, {
        enabled: function enabled() {
          return !!field && !!value.value.value;
        },
        key: key
      });
    });
  }, {
    immediate: true
  });
  setQueryFilters("filters", function () {
    var _getDaysOnMarketFilte = getDaysOnMarketFilterById(daysOnMarket.value),
      dayMin = _getDaysOnMarketFilte.dayMin,
      dayMax = _getDaysOnMarketFilte.dayMax;
    return DaysOnMarketFilter(dayMin, dayMax);
  }, {
    enabled: function enabled() {
      var _daysOnMarket$value;
      return ((_daysOnMarket$value = daysOnMarket.value) !== null && _daysOnMarket$value !== void 0 ? _daysOnMarket$value : 0) > 0;
    }
  });
  setQueryFilters("filters", function () {
    return rentStatus.value === "vacant" ? vacantUnitFilter(selectedDate.value) : rentStatus.value === "rerent" ? rerentFilter() : undefined;
  }, {
    enabled: function enabled() {
      return !!rentStatus.value;
    }
  });
  setQueryFilters("filters", function () {
    return {
      matches: [{
        status: ["in", salesStatus.value]
      }]
    };
  }, {
    enabled: function enabled() {
      var _salesStatus$value;
      return !!((_salesStatus$value = salesStatus.value) !== null && _salesStatus$value !== void 0 && _salesStatus$value.length);
    }
  });
  setQueryFilters("filters", function () {
    var _search$value;
    return tenanciesSearchFilter((_search$value = search.value) !== null && _search$value !== void 0 ? _search$value : "");
  }, {
    enabled: function enabled() {
      return !!search.value;
    }
  });
  setQueryFilters("filters", function () {
    return tenancyTypeFilter(tenancyTypes.value);
  }, {
    enabled: function enabled() {
      var _tenancyTypes$value;
      return !!((_tenancyTypes$value = tenancyTypes.value) !== null && _tenancyTypes$value !== void 0 && _tenancyTypes$value.length);
    }
  });
  setQueryFilters("filters", function () {
    return portfolioIdsFilter(portfolioIds.value);
  }, {
    enabled: function enabled() {
      var _portfolioIds$value;
      return !!((_portfolioIds$value = portfolioIds.value) !== null && _portfolioIds$value !== void 0 && _portfolioIds$value.length);
    }
  });
  setQueryFilters("filters", function () {
    return propertyIdsFilter(propertyIds.value);
  }, {
    enabled: function enabled() {
      var _propertyIds$value;
      return !!((_propertyIds$value = propertyIds.value) !== null && _propertyIds$value !== void 0 && _propertyIds$value.length);
    }
  });
  setQueryFilters("filters", function () {
    return tenancyAdminStartFilter(startFrom.value, startTo.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return !!startFrom.value || !!startTo.value;
    }
  });
  setQueryFilters("filters", function () {
    return tenancyAdminEndFilter(endFrom.value, endTo.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return !!endFrom.value || !!endTo.value;
    }
  });
  setQueryFilters("filters", function () {
    return propertyAdminStartFilter(propertyAdminStartFrom.value, propertyAdminStartTo.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return !!propertyAdminStartFrom.value || !!propertyAdminStartTo.value;
    }
  });
  setQueryFilters("filters", function () {
    return propertyAdminEndFilter(propertyAdminEndFrom.value, propertyAdminEndTo.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return !!propertyAdminEndFrom.value || !!propertyAdminEndTo.value;
    }
  });
  setQueryFilters("filters", function () {
    return zipRangeFilter(zipFrom.value, zipTo.value);
  }, {
    enabled: function enabled() {
      return !!zipFrom.value || !!zipTo.value;
    }
  });
  setQueryFilters("filters", function () {
    return areaRangeFilter(areaFrom.value, areaTo.value);
  }, {
    enabled: function enabled() {
      return hasValue(areaFrom.value) || hasValue(areaTo.value);
    }
  });
  setQueryFilters("filters", function () {
    return arrearsFilter(arrears.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return hasValue(arrears.value);
    }
  });
  setQueryFilters("filters", function () {
    return irrevocableFilter("current_tenant_irrevocable_until_tenant", irrTenant.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return !!irrTenant.value;
    },
    key: "current_tenant_irrevocable_until_tenant"
  });
  setQueryFilters("filters", function () {
    return irrevocableFilter("current_tenant_irrevocable_until_landlord", irrLandlord.value, selectedDate.value);
  }, {
    enabled: function enabled() {
      return !!irrLandlord.value;
    },
    key: "current_tenant_irrevocable_until_landlord"
  });
  setQueryFilters("filters", function () {
    return rentRegulationFilter(rentRegulationPrinciples.value);
  }, {
    enabled: function enabled() {
      var _rentRegulationPrinci;
      return !!((_rentRegulationPrinci = rentRegulationPrinciples.value) !== null && _rentRegulationPrinci !== void 0 && _rentRegulationPrinci.length);
    }
  });
  setQueryFilters("filters", function () {
    return excludeTenancyFromResultsFilter();
  }, {
    enabled: function enabled() {
      return !variables.value.ignoreResultsFlag;
    }
  });
  setQueryFilters("filters", function () {
    return assignedToFilter(assignedTo.value);
  }, {
    enabled: function enabled() {
      return !!assignedTo.value;
    }
  });
  return {
    result: computed(function () {
      return customFieldsLoading.value ? undefined : {
        filters: queryFilters.value.filters,
        pagination: pagination.value,
        sort: sort.value,
        rentCategory: PlannedRentsMetricsCategory.Rent,
        date: date.value,
        includeFlags: !!variables.value.includeFlags || !variables.value.ignoreResultsFlag,
        useLatest: !date.value,
        useAtDate: !!date.value
      };
    }),
    loading: computed(function () {
      return customFieldsLoading.value;
    })
  };
};