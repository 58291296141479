import moment from "moment";

import {
  TenancyCustomFieldsDocument,
  UpdateTenancyCustomFieldDocument,
  CreateTenancyCustomFieldDocument,
  DeleteTenancyCustomFieldDocument,
  AssetManagementCustomFieldType,
  AssetManagementTenancyStatus,
  AssetManagementTenancyFlagValue,
} from "~/graphql/generated/graphql";
import { getLatestSalesFinances, getDaysOnMarket, type SalesFinance } from "./portfolio-sales-helpers";
import { type Tenant, isVacantTenant, type RentMetric } from "./portfolio-tenant-helpers";
import { getBrokerMandate, type Valuation } from "./portfolio-valuation-helpers";
import { filterWithValue, hasValue } from "../common-helpers";
import { type TenanciesColumnGroupKey, type TenanciesColumnKey, type TenanciesCustomFieldKey } from "../user-preferences-helpers";

import { getPortfolioColorBackground, getPortfolioColorForeground } from "./portfolio-helpers";
import type { InjectionKey, Ref } from "vue";
import type { DataTableRowSaveFunction, TableContext, TableHeaderConfig } from "~/helpers/datatable-helpers";
import { useApolloClient, type MutateOverrideOptions } from "@vue/apollo-composable";
import type { CustomField } from "./portfolio-custom-field-helpers";
import type { BusinessPlan, FinancialRecord, Location, Property, PropertyFlag } from "./portfolio-property-helpers";
import { Currency } from "../apollo/types";

export type TenancyCustomField = { customField: CustomField; value?: Nullable<string> };

export type ExternalId = { externalID: string; externalIDType: string };

export type Responsible = { id: string; name?: Nullable<string> };

export type TenancyFlag = { id: string; value: AssetManagementTenancyFlagValue };

export type ExternalEntity = { assetManagementExternalIDs?: Nullable<{ items: ExternalId[] }> };

export type SalesEntity = { assetManagementSales?: Nullable<{ items: SalesFinance[] }> };

export type CustomFieldsEntity = { assetManagementCustomFields?: TenancyCustomField[] };

export type ResponsiblesEntity = { assetManagementResponsibles?: Responsible[] };

export type FlagsEntity<F extends TenancyFlag | PropertyFlag> = { flags?: F[] };

export type TenantEntity = {
  assetManagementLatestTenant?: Nullable<Tenant>;
  assetManagementTenants?: {
    items?: Nullable<Tenant[]>;
  };
};

export type PlannedRent = { startDate: string; amount: number };
export type Tenancy = {
  id: string;
  plannedRent?: Nullable<RentMetric>;
  latestPlannedRent?: Nullable<RentMetric>;
  area?: Nullable<number>;
  areaUnit?: Nullable<string>;
  tenancyType?: Nullable<string>;
  rentRegulationPrinciple?: Nullable<string>;
  rooms?: Nullable<number>;
  status?: Nullable<AssetManagementTenancyStatus>;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  assetManagementPlannedRents?: Nullable<{ items: PlannedRent[] }>;
  assetManagementLatestTenant?: Nullable<Tenant>;
  assetManagementValuations?: Nullable<{ items: Valuation[] }>;

  assetManagementBusinessPlans?: Nullable<{ items: BusinessPlan[] }>;
  assetManagementProperty?: Nullable<Property>;
  assetManagementFinancialRecords?: Nullable<{
    items: FinancialRecord[];
  }>;
} & ExternalEntity &
  SalesEntity &
  CustomFieldsEntity &
  ResponsiblesEntity &
  TenantEntity &
  FlagsEntity<TenancyFlag> &
  Location;

export type IdleTenancy = {
  period: any;
  occupiedDays: number;
  possibleDays: number;
  lostRent?: number | null;
  possibleRent?: number | null;
  possibleDaysWithRent: number;
  occupiedDaysWithRent: number;
};

export type LeaseMetrics = {
  totalTenancies: number;
  period: any;
  evictions: number;
};

export type PlannedRentMetrics = {
  period: any;
  amount: number;
  currency: string;
};

export enum TenancyStatus {
  OCCUPIED = "OCCUPIED",
  VACANT = "VACANT",
  INACTIVE = "INACTIVE",
}

export type TenanciesDate = string | "today";

export const getTenancyAddress = (location: Location) => {
  let address = location.name || "";

  if (location.zipCode) {
    address += `, ${location.zipCode}`;
  }

  if (location.city) {
    address += ` ${location.city}`;
  }

  return address;
};

export const getRentCurrency = (tenancy: Nullable<Tenancy>) => (tenancy?.plannedRent ?? tenancy?.latestPlannedRent)?.currency;

export const getYearlyRent = (tenancy: Nullable<Tenancy>) => (tenancy?.plannedRent ?? tenancy?.latestPlannedRent)?.amount || 0;

export const getMonthlyRent = (tenancy: Tenancy) => getYearlyRent(tenancy) / 12;

export const getMonthlyRentPerArea = (tenancy: Tenancy) => (tenancy.area ? getMonthlyRent(tenancy) / tenancy.area : null);

export const getRentPerAreaPerAnnum = (tenancy: Tenancy) => (tenancy.area ? getYearlyRent(tenancy) / tenancy.area : null);

export const getRentPrDate = (tenancy: Tenancy, startDate: string, endDate: string) => {
  const rents = tenancy.assetManagementPlannedRents?.items.filter((item) => {
    return endDate ? moment(item.startDate).isBetween(startDate, endDate, null, "[]") : moment(item.startDate).isAfter(startDate);
  });

  const mostRecentRent = rents?.reduce((acc, item) => {
    return item.startDate > acc.startDate ? item : acc;
  });

  return mostRecentRent?.amount || 0;
};

export const getCurrentTenant = (tenancy: { assetManagementLatestTenant?: Nullable<Tenant> }) => {
  return tenancy.assetManagementLatestTenant;
};

export const getCurrentTenantBalance = (tenancy: TenantEntity) => {
  const tenant = tenancy?.assetManagementLatestTenant;
  return tenant?.balanceAt ?? tenant?.latestBalance;
};

export const getLeaseStart = (tenancy: Tenancy, tenant?: Nullable<Tenant>) => {
  tenant ??= getCurrentTenant(tenancy);

  return tenant?.moveInDate;
};

export const getLeaseEnd = (tenancy: Tenancy, tenant?: Nullable<Tenant>) => {
  tenant ??= getCurrentTenant(tenancy);

  return tenant?.evictionDate;
};

export const getExternalIdForTenancy = (tenancy: ExternalEntity) => {
  const externalId = tenancy?.assetManagementExternalIDs?.items.find((item) => {
    return item.externalIDType == "id";
  });

  return externalId?.externalID ?? null;
};

export const isVacant = (tenancy: { id: string; assetManagementLatestTenant?: Nullable<Tenant> }) => {
  const currentTenant = getCurrentTenant(tenancy);

  return isVacantTenant(currentTenant);
};

export const getAskingPrice = (tenancy: Tenancy) => {
  if (tenancy.assetManagementValuations?.items == null || tenancy.assetManagementValuations.items.length == 0) {
    return undefined;
  }

  const selectedValuation = tenancy.assetManagementValuations.items.find((item) => item.isAgentSelected) || tenancy.assetManagementValuations.items[0];

  return selectedValuation?.askingPrice;
};

export const getActualSalesPrice = (tenancy: Tenancy) => {
  if (tenancy.assetManagementSales?.items == null || tenancy.assetManagementSales.items.length == 0) {
    return undefined;
  }

  return getLatestSalesFinances(tenancy?.assetManagementSales?.items)?.actualSalesPrice;
};

export const getTenancyDaysOnMarket = (tenancy: SalesEntity) => {
  const latestSales = getLatestSalesFinances(tenancy?.assetManagementSales?.items);

  if (!latestSales) {
    return null;
  }

  return getDaysOnMarket(latestSales);
};

export const getTenancyBrokerMandate = (tenancy: Tenancy) => {
  const valuations = tenancy.assetManagementValuations?.items;

  if (valuations == null || valuations.length == 0) {
    return 0;
  }

  return getBrokerMandate(valuations);
};

export const getTenancyPortfolioColorForeground = (tenancy: Tenancy) => getPortfolioColorForeground(tenancy.assetManagementProperty?.assetManagementPortfolio);

export const getTenancyPortfolioColorBackground = (tenancy: Tenancy) => getPortfolioColorBackground(tenancy.assetManagementProperty?.assetManagementPortfolio);

export const getBusinessPlanForTenancy = (tenancy: Tenancy) => {
  // Note (Morten): Pick the latest updated business plan
  if (tenancy.assetManagementBusinessPlans?.items == null || tenancy.assetManagementBusinessPlans?.items.length == 0) {
    return null;
  }

  return [...tenancy.assetManagementBusinessPlans.items].sort((a, b) => {
    return a?.updatedAt && b?.updatedAt ? moment(b.updatedAt).diff(moment(a.updatedAt)) : 0;
  })[0];
};

export const getSortedTenancyHeaders = (headers: TableHeaderConfig<Tenancy, TenanciesColumnKey>[], preferencesColumns: TenanciesColumnKey[]) =>
  filterWithValue(preferencesColumns.map((column) => headers.find((header): boolean => header.id === column)));

export type ColumnGroup = { name: TenanciesColumnGroupKey; columns: { key: TenanciesColumnKey; enabled: boolean }[] };

export const parseCustomFieldKey = (key: TenanciesCustomFieldKey): { id: string; name: string } => {
  const [, id, name] = key.split("_");

  return { id, name };
};

export const stringifyCustomField = ({ id, name }: { id: string; name: string }) => `custom_${id}_${name}` as TenanciesCustomFieldKey;

export const getGroupedTenancyColumns = (preferencesColumns: TenanciesColumnKey[], customFields: TenanciesCustomFieldKey[]): ColumnGroup[] => {
  const groups: ColumnGroup[] = TenanciesUserPreferenceColumnGroups.map((group) => ({
    name: group.name,
    columns: group.columns.map((column) => ({ key: column, enabled: preferencesColumns.includes(column) })),
  }));

  if (customFields) {
    groups.push({
      name: "custom",
      columns: customFields.map((key) => ({ key, enabled: preferencesColumns.includes(key) })),
    });
  }

  return groups;
};

export const getTenancyStatus = (tenancy: { id: string; endDate?: Nullable<string>; assetManagementLatestTenant?: Nullable<Tenant> }): TenancyStatus => {
  if (moment(tenancy.endDate).isBefore(moment())) {
    return TenancyStatus.INACTIVE;
  }

  if (isVacant(tenancy)) {
    return TenancyStatus.VACANT;
  }

  return TenancyStatus.OCCUPIED;
};

export const getRentRegulationPrinciple = (tenancy: Nullable<Tenancy>) => {
  return tenancy?.rentRegulationPrinciple;
};

export const tableHeaderRender = <T>(header: TableHeaderConfig<T>, row: T, tableContext: TableContext<T>) => {
  const filter = header.dataTableCellOptions?.filter;
  const value = header.value(row);
  return filter ? filter(value, row, tableContext) : value;
};

export const getTenanciesColumns = (savedColumns?: Nullable<string[]>, userPreferenceColumns?: Nullable<TenanciesColumnKey[]>) => {
  return (savedColumns as TenanciesColumnKey[]) ?? userPreferenceColumns ?? undefined;
};

export const getOnSaveTenanciesCustomTextField = (field: CustomField) => {
  return (async (rowValue: Nullable<string | number>, row: Tenancy) => {
    const customFieldId = field.id;

    const value = field.fieldType === AssetManagementCustomFieldType.Boolean ? Boolean(rowValue) : rowValue?.toString();

    const { client } = useApolloClient();

    /**Note Sven: We avoid refetch to not lose current row order in the table after editing (confusing as a user)
     * This means we may have a state where the tenancyCustomField was previously deleted without the client knowing
     * We fetch the current ones here, to see if it already exists;
     */
    const customFieldsQuery = await client.query({ query: TenancyCustomFieldsDocument, variables: { tenancyId: row.id }, fetchPolicy: "no-cache" });

    const tenancyCustomField = customFieldsQuery.data.assetManagementTenancy?.assetManagementCustomFields.find(
      (field) => field.customField.id === customFieldId
    );
    const tenancyFieldId = tenancyCustomField?.id;
    const options: MutateOverrideOptions<any> = { refetchQueries: [], awaitRefetchQueries: true };

    let newValue: string | number | undefined = undefined;

    if (!!value) {
      const update = !!tenancyFieldId;

      let res = update
        ? await client.mutate({
            ...options,
            mutation: UpdateTenancyCustomFieldDocument,
            variables: {
              input: {
                id: tenancyFieldId,
                value,
              },
            },
          })
        : await client.mutate({
            ...options,
            mutation: CreateTenancyCustomFieldDocument,
            variables: {
              input: {
                customFieldId,
                tenancyId: row.id,
                value,
              },
            },
          });

      newValue = res?.data?.result.value;
    } else if (tenancyFieldId) {
      await client.mutate({ ...options, mutation: DeleteTenancyCustomFieldDocument, variables: { id: tenancyFieldId } });
    }

    return field.fieldType === AssetManagementCustomFieldType.Boolean ? Boolean(newValue) : newValue;
  }) as DataTableRowSaveFunction<any, Tenancy, TenanciesColumnKey>;
};

export const TenanciesColumnsInjectionKey = Symbol("tenanciesColumnsInjectionKey") as InjectionKey<Ref<TenanciesColumnKey[] | undefined>>;

export const TenanciesUserPreferenceColumns = [
  {
    key: "name",
    enabled: true,
  },
  {
    key: "tenantName",
    enabled: true,
  },
  {
    key: "unitNumber",
    enabled: true,
  },
  {
    key: "usage",
    enabled: true,
  },
  {
    key: "numberOfRooms",
    enabled: true,
  },
  {
    key: "area",
    enabled: true,
  },
  {
    key: "salesStatus",
    enabled: false,
  },
  {
    key: "takeoverDate",
    enabled: false,
  },

  {
    key: "monthlyRent",
    enabled: false,
  },

  {
    key: "monthlyRentPsm",
    enabled: false,
  },

  {
    key: "annualRent",
    enabled: true,
  },

  {
    key: "rentPsm",
    enabled: true,
  },
  {
    key: "leaseStart",
    enabled: true,
  },

  {
    key: "leaseEnd",
    enabled: true,
  },

  {
    key: "adminStart",
    enabled: false,
  },

  {
    key: "adminEnd",
    enabled: false,
  },

  {
    key: "propertyAdminStart",
    enabled: false,
  },

  {
    key: "propertyAdminEnd",
    enabled: false,
  },

  {
    key: "daysOnMarket",
    enabled: false,
  },
  {
    key: "brokerMandate",
    enabled: false,
  },

  {
    key: "zip",
    enabled: false,
  },

  {
    key: "portfolio",
    enabled: false,
  },
  {
    key: "arrears",
    enabled: true,
  },

  {
    key: "irrevocableUntilLandlord",
    enabled: true,
  },

  {
    key: "irrevocableUntilTenant",
    enabled: true,
  },

  {
    key: "leaseLength",
    enabled: false,
  },

  {
    key: "rentRegulationPrinciple",
    enabled: true,
  },
  {
    key: "property",
    enabled: false,
  },
  {
    key: "assignedTo",
    enabled: false,
  },
] as const;

export const TenanciesUserPreferenceColumnGroups = [
  {
    name: "unit",
    columns: [
      "name",
      "unitNumber",
      "area",
      "numberOfRooms",
      "usage",
      "adminStart",
      "adminEnd",
      "propertyAdminStart",
      "propertyAdminEnd",
      "rentRegulationPrinciple",
      "property",
      "portfolio",
      "zip",
      "assignedTo",
    ],
  },
  {
    name: "tenant",
    columns: [
      "tenantName",
      "monthlyRent",
      "monthlyRentPsm",
      "annualRent",
      "rentPsm",
      "leaseStart",
      "leaseEnd",
      "leaseLength",
      "arrears",
      "irrevocableUntilTenant",
      "irrevocableUntilLandlord",
    ],
  },
  { name: "sales", columns: ["salesStatus", "brokerMandate", "daysOnMarket", "takeoverDate"] },
] as const satisfies { name: string; columns: TenanciesColumnKey[] }[];
