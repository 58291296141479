import Vue from "vue";
import * as Sentry from "@sentry/vue";

export default ({ app }) => {
  Sentry.init({
    Vue,
    dsn: "https://0ad3ed54831c42ffb8e572f351e0df01@o574608.ingest.sentry.io/5725825",

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.5,
    environment: process.env.buildVariant,
    release: process.env.version,
    logErrors: true,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(app.router),
        tracingOrigins: ["router.estaid.dk"],
      }),
      new Sentry.Replay()
    ],
  });
};
