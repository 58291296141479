import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1507b58f = () => interopDefault(import('../pages/analytics/index.vue' /* webpackChunkName: "pages/analytics/index" */))
const _aa576342 = () => interopDefault(import('../pages/datawarehouse.vue' /* webpackChunkName: "pages/datawarehouse" */))
const _a7d2148e = () => interopDefault(import('../pages/datawarehouse/catalogue.vue' /* webpackChunkName: "pages/datawarehouse/catalogue" */))
const _7674e036 = () => interopDefault(import('../pages/datawarehouse/catalogue/categories/index.vue' /* webpackChunkName: "pages/datawarehouse/catalogue/categories/index" */))
const _73c16102 = () => interopDefault(import('../pages/datawarehouse/catalogue/custom-fields/index.vue' /* webpackChunkName: "pages/datawarehouse/catalogue/custom-fields/index" */))
const _184ae734 = () => interopDefault(import('../pages/datawarehouse/catalogue/portfolios/index.vue' /* webpackChunkName: "pages/datawarehouse/catalogue/portfolios/index" */))
const _1178cc6d = () => interopDefault(import('../pages/datawarehouse/catalogue/categories/helpers.ts' /* webpackChunkName: "pages/datawarehouse/catalogue/categories/helpers" */))
const _32bfbd4e = () => interopDefault(import('../pages/datawarehouse/overview.vue' /* webpackChunkName: "pages/datawarehouse/overview" */))
const _d603ab94 = () => interopDefault(import('../pages/datawarehouse/property-mapping.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping" */))
const _14344f71 = () => interopDefault(import('../pages/datawarehouse/property-mapping/flags/index.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/flags/index" */))
const _5124c752 = () => interopDefault(import('../pages/datawarehouse/property-mapping/portfolios/index.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/portfolios/index" */))
const _60c172e3 = () => interopDefault(import('../pages/datawarehouse/property-mapping/types/index.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/types/index" */))
const _1220412f = () => interopDefault(import('../pages/datawarehouse/property-mapping/flags/PropertyFlagValueTickbox.vue' /* webpackChunkName: "pages/datawarehouse/property-mapping/flags/PropertyFlagValueTickbox" */))
const _49b0fa51 = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping" */))
const _29fe61e8 = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping/flags/index.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping/flags/index" */))
const _ac9a3d8a = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping/responsible/index.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping/responsible/index" */))
const _26601723 = () => interopDefault(import('../pages/datawarehouse/tenancy-mapping/flags/TenancyFlagValueTickbox.vue' /* webpackChunkName: "pages/datawarehouse/tenancy-mapping/flags/TenancyFlagValueTickbox" */))
const _d9b0731a = () => interopDefault(import('../pages/datawarehouse/testdata.js' /* webpackChunkName: "pages/datawarehouse/testdata" */))
const _9df0fa72 = () => interopDefault(import('../pages/datawarehouse/components.old/TableCellMessage.vue' /* webpackChunkName: "pages/datawarehouse/components.old/TableCellMessage" */))
const _582bf8e2 = () => interopDefault(import('../pages/datawarehouse/components.old/TableCellStatus.vue' /* webpackChunkName: "pages/datawarehouse/components.old/TableCellStatus" */))
const _168d2bdc = () => interopDefault(import('../pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _37296ece = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1bcba93d = () => interopDefault(import('../pages/my-estaid.vue' /* webpackChunkName: "pages/my-estaid" */))
const _17d110f4 = () => interopDefault(import('../pages/my-estaid/lists/index.vue' /* webpackChunkName: "pages/my-estaid/lists/index" */))
const _bfecb924 = () => interopDefault(import('../pages/my-estaid/lists/_id.vue' /* webpackChunkName: "pages/my-estaid/lists/_id" */))
const _4023565e = () => interopDefault(import('../pages/portfolio/index.vue' /* webpackChunkName: "pages/portfolio/index" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _de57cf96 = () => interopDefault(import('../pages/template-builder/index.vue' /* webpackChunkName: "pages/template-builder/index" */))
const _199cd4f8 = () => interopDefault(import('../pages/testbed/index.vue' /* webpackChunkName: "pages/testbed/index" */))
const _50c6f8ea = () => interopDefault(import('../pages/explore/advanced/index.vue' /* webpackChunkName: "pages/explore/advanced/index" */))
const _41dee7c7 = () => interopDefault(import('../pages/portfolio/properties/index.vue' /* webpackChunkName: "pages/portfolio/properties/index" */))
const _288d5a40 = () => interopDefault(import('../pages/portfolio/tenancies/index.vue' /* webpackChunkName: "pages/portfolio/tenancies/index" */))
const _7308efea = () => interopDefault(import('../pages/portfolio/test-data.js' /* webpackChunkName: "pages/portfolio/test-data" */))
const _a6c170e2 = () => interopDefault(import('../pages/template-builder/template-data.js' /* webpackChunkName: "pages/template-builder/template-data" */))
const _0e1a3ff0 = () => interopDefault(import('../pages/explore/area/overview/index.vue' /* webpackChunkName: "pages/explore/area/overview/index" */))
const _77c8b2ac = () => interopDefault(import('../pages/explore/area/rent-levels/index.vue' /* webpackChunkName: "pages/explore/area/rent-levels/index" */))
const _4b037f06 = () => interopDefault(import('../pages/template-builder/helpers/template-helpers.js' /* webpackChunkName: "pages/template-builder/helpers/template-helpers" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5afd0fb2 = () => interopDefault(import('../pages/explore/company/_id.vue' /* webpackChunkName: "pages/explore/company/_id" */))
const _68552447 = () => interopDefault(import('../pages/explore/company/_id/key-figures/index.vue' /* webpackChunkName: "pages/explore/company/_id/key-figures/index" */))
const _acfae12e = () => interopDefault(import('../pages/explore/company/_id/overview/index.vue' /* webpackChunkName: "pages/explore/company/_id/overview/index" */))
const _74f9af56 = () => interopDefault(import('../pages/explore/company/_id/ownership/index.vue' /* webpackChunkName: "pages/explore/company/_id/ownership/index" */))
const _6f23723f = () => interopDefault(import('../pages/explore/company/_id/people/index.vue' /* webpackChunkName: "pages/explore/company/_id/people/index" */))
const _503936ee = () => interopDefault(import('../pages/explore/company/_id/portfolio/index.vue' /* webpackChunkName: "pages/explore/company/_id/portfolio/index" */))
const _5ddff5c5 = () => interopDefault(import('../pages/explore/company/_id/transactions/index.vue' /* webpackChunkName: "pages/explore/company/_id/transactions/index" */))
const _57c37866 = () => interopDefault(import('../pages/explore/person/_id.vue' /* webpackChunkName: "pages/explore/person/_id" */))
const _4cd19a69 = () => interopDefault(import('../pages/explore/person/_id/index.vue' /* webpackChunkName: "pages/explore/person/_id/index" */))
const _b6a1af96 = () => interopDefault(import('../pages/explore/person/_id/overview/index.vue' /* webpackChunkName: "pages/explore/person/_id/overview/index" */))
const _2fe9a909 = () => interopDefault(import('../pages/explore/person/_id/ownership/index.vue' /* webpackChunkName: "pages/explore/person/_id/ownership/index" */))
const _2a57acde = () => interopDefault(import('../pages/explore/person/_id/relations/index.vue' /* webpackChunkName: "pages/explore/person/_id/relations/index" */))
const _4c3b4891 = () => interopDefault(import('../pages/explore/person/_id/transactions/index.vue' /* webpackChunkName: "pages/explore/person/_id/transactions/index" */))
const _5336ff66 = () => interopDefault(import('../pages/explore/property/_id.vue' /* webpackChunkName: "pages/explore/property/_id" */))
const _0f43e169 = () => interopDefault(import('../pages/explore/property/_id/index.vue' /* webpackChunkName: "pages/explore/property/_id/index" */))
const _bff9164e = () => interopDefault(import('../pages/explore/property/_id/buildings/index.vue' /* webpackChunkName: "pages/explore/property/_id/buildings/index" */))
const _7b39c986 = () => interopDefault(import('../pages/explore/property/_id/commercial/index.vue' /* webpackChunkName: "pages/explore/property/_id/commercial/index" */))
const _2b28e4f6 = () => interopDefault(import('../pages/explore/property/_id/commercial-rent/index.vue' /* webpackChunkName: "pages/explore/property/_id/commercial-rent/index" */))
const _7a018aec = () => interopDefault(import('../pages/explore/property/_id/constructioncases/index.vue' /* webpackChunkName: "pages/explore/property/_id/constructioncases/index" */))
const _a150ffdc = () => interopDefault(import('../pages/explore/property/_id/documents/index.vue' /* webpackChunkName: "pages/explore/property/_id/documents/index" */))
const _a2f0f37e = () => interopDefault(import('../pages/explore/property/_id/easements/index.vue' /* webpackChunkName: "pages/explore/property/_id/easements/index" */))
const _271d91f1 = () => interopDefault(import('../pages/explore/property/_id/mortgages/index.vue' /* webpackChunkName: "pages/explore/property/_id/mortgages/index" */))
const _3e467d96 = () => interopDefault(import('../pages/explore/property/_id/overview/index.vue' /* webpackChunkName: "pages/explore/property/_id/overview/index" */))
const _48805148 = () => interopDefault(import('../pages/explore/property/_id/owners/index.vue' /* webpackChunkName: "pages/explore/property/_id/owners/index" */))
const _763d0812 = () => interopDefault(import('../pages/explore/property/_id/rental-levels/index.vue' /* webpackChunkName: "pages/explore/property/_id/rental-levels/index" */))
const _25aa6ce3 = () => interopDefault(import('../pages/explore/property/_id/tenants/index.vue' /* webpackChunkName: "pages/explore/property/_id/tenants/index" */))
const _15f46191 = () => interopDefault(import('../pages/explore/property/_id/transactions/index.vue' /* webpackChunkName: "pages/explore/property/_id/transactions/index" */))
const _4232335d = () => interopDefault(import('../pages/explore/property/_id/rental-levels/PageLejedata.vue' /* webpackChunkName: "pages/explore/property/_id/rental-levels/PageLejedata" */))
const _5d5cc986 = () => interopDefault(import('../pages/explore/reference-properties/_id.vue' /* webpackChunkName: "pages/explore/reference-properties/_id" */))
const _55afa106 = () => interopDefault(import('../pages/portfolio/property/_propertyId.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId" */))
const _0fda0c96 = () => interopDefault(import('../pages/portfolio/property/_propertyId/balance/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/balance/index" */))
const _5727e726 = () => interopDefault(import('../pages/portfolio/property/_propertyId/capexes/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/capexes/index" */))
const _2c9af11e = () => interopDefault(import('../pages/portfolio/property/_propertyId/opexes/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/opexes/index" */))
const _5718ab95 = () => interopDefault(import('../pages/portfolio/property/_propertyId/overview/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/overview/index" */))
const _e29672f4 = () => interopDefault(import('../pages/portfolio/property/_propertyId/sales/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/sales/index" */))
const _628e7529 = () => interopDefault(import('../pages/portfolio/property/_propertyId/units/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/units/index" */))
const _eae47da4 = () => interopDefault(import('../pages/portfolio/property/_propertyId/valuations/index.vue' /* webpackChunkName: "pages/portfolio/property/_propertyId/valuations/index" */))
const _2314873c = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId" */))
const _788edc23 = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/capexes/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/capexes/index" */))
const _374fd29f = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/overview/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/overview/index" */))
const _6d940bbc = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/sales/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/sales/index" */))
const _123a1b50 = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/tenant/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/tenant/index" */))
const _39974db8 = () => interopDefault(import('../pages/portfolio/tenancy/_tenancyId/valuations/index.vue' /* webpackChunkName: "pages/portfolio/tenancy/_tenancyId/valuations/index" */))
const _1276d666 = () => interopDefault(import('../pages/download/packaged/_id/_filename.vue' /* webpackChunkName: "pages/download/packaged/_id/_filename" */))
const _0126f1d6 = () => interopDefault(import('../pages/portfolio/_portfolioId.vue' /* webpackChunkName: "pages/portfolio/_portfolioId" */))
const _65e7ac65 = () => interopDefault(import('../pages/portfolio/_portfolioId/balance/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/balance/index" */))
const _cd2fedb8 = () => interopDefault(import('../pages/portfolio/_portfolioId/companies/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/companies/index" */))
const _a4d7bc7e = () => interopDefault(import('../pages/portfolio/_portfolioId/opex/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/opex/index" */))
const _7a81f4b4 = () => interopDefault(import('../pages/portfolio/_portfolioId/overview/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/overview/index" */))
const _45b6f860 = () => interopDefault(import('../pages/portfolio/_portfolioId/properties/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/properties/index" */))
const _ee7f69b2 = () => interopDefault(import('../pages/portfolio/_portfolioId/tenancies/index.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/tenancies/index" */))
const _63f91aca = () => interopDefault(import('../pages/portfolio/_portfolioId/composables/useQueryWithPeriod.ts' /* webpackChunkName: "pages/portfolio/_portfolioId/composables/useQueryWithPeriod" */))
const _67aea371 = () => interopDefault(import('../pages/portfolio/_portfolioId/overview/index.overview.vue' /* webpackChunkName: "pages/portfolio/_portfolioId/overview/index.overview" */))
const _4179936e = () => interopDefault(import('../pages/reset-password/_id.vue' /* webpackChunkName: "pages/reset-password/_id" */))
const _2f84a3dd = () => interopDefault(import('../pages/template-builder/_id.vue' /* webpackChunkName: "pages/template-builder/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/analytics",
    component: _1507b58f,
    name: "analytics"
  }, {
    path: "/datawarehouse",
    component: _aa576342,
    name: "datawarehouse",
    children: [{
      path: "catalogue",
      component: _a7d2148e,
      name: "datawarehouse-catalogue",
      children: [{
        path: "categories",
        component: _7674e036,
        name: "datawarehouse-catalogue-categories"
      }, {
        path: "custom-fields",
        component: _73c16102,
        name: "datawarehouse-catalogue-custom-fields"
      }, {
        path: "portfolios",
        component: _184ae734,
        name: "datawarehouse-catalogue-portfolios"
      }, {
        path: "categories/helpers",
        component: _1178cc6d,
        name: "datawarehouse-catalogue-categories-helpers"
      }]
    }, {
      path: "overview",
      component: _32bfbd4e,
      name: "datawarehouse-overview"
    }, {
      path: "property-mapping",
      component: _d603ab94,
      name: "datawarehouse-property-mapping",
      children: [{
        path: "flags",
        component: _14344f71,
        name: "datawarehouse-property-mapping-flags"
      }, {
        path: "portfolios",
        component: _5124c752,
        name: "datawarehouse-property-mapping-portfolios"
      }, {
        path: "types",
        component: _60c172e3,
        name: "datawarehouse-property-mapping-types"
      }, {
        path: "flags/PropertyFlagValueTickbox",
        component: _1220412f,
        name: "datawarehouse-property-mapping-flags-PropertyFlagValueTickbox"
      }]
    }, {
      path: "tenancy-mapping",
      component: _49b0fa51,
      name: "datawarehouse-tenancy-mapping",
      children: [{
        path: "flags",
        component: _29fe61e8,
        name: "datawarehouse-tenancy-mapping-flags"
      }, {
        path: "responsible",
        component: _ac9a3d8a,
        name: "datawarehouse-tenancy-mapping-responsible"
      }, {
        path: "flags/TenancyFlagValueTickbox",
        component: _26601723,
        name: "datawarehouse-tenancy-mapping-flags-TenancyFlagValueTickbox"
      }]
    }, {
      path: "testdata",
      component: _d9b0731a,
      name: "datawarehouse-testdata"
    }, {
      path: "components.old/TableCellMessage",
      component: _9df0fa72,
      name: "datawarehouse-components.old-TableCellMessage"
    }, {
      path: "components.old/TableCellStatus",
      component: _582bf8e2,
      name: "datawarehouse-components.old-TableCellStatus"
    }]
  }, {
    path: "/explore",
    component: _168d2bdc,
    name: "explore"
  }, {
    path: "/forgot-password",
    component: _37296ece,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/my-estaid",
    component: _1bcba93d,
    name: "my-estaid",
    children: [{
      path: "lists",
      component: _17d110f4,
      name: "my-estaid-lists"
    }, {
      path: "lists/:id",
      component: _bfecb924,
      name: "my-estaid-lists-id"
    }]
  }, {
    path: "/portfolio",
    component: _4023565e,
    name: "portfolio"
  }, {
    path: "/settings",
    component: _2c20a960,
    name: "settings"
  }, {
    path: "/template-builder",
    component: _de57cf96,
    name: "template-builder"
  }, {
    path: "/testbed",
    component: _199cd4f8,
    name: "testbed"
  }, {
    path: "/explore/advanced",
    component: _50c6f8ea,
    name: "explore-advanced"
  }, {
    path: "/portfolio/properties",
    component: _41dee7c7,
    name: "portfolio-properties"
  }, {
    path: "/portfolio/tenancies",
    component: _288d5a40,
    name: "portfolio-tenancies"
  }, {
    path: "/portfolio/test-data",
    component: _7308efea,
    name: "portfolio-test-data"
  }, {
    path: "/template-builder/template-data",
    component: _a6c170e2,
    name: "template-builder-template-data"
  }, {
    path: "/explore/area/overview",
    component: _0e1a3ff0,
    name: "explore-area-overview"
  }, {
    path: "/explore/area/rent-levels",
    component: _77c8b2ac,
    name: "explore-area-rent-levels"
  }, {
    path: "/template-builder/helpers/template-helpers",
    component: _4b037f06,
    name: "template-builder-helpers-template-helpers"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/explore/company/:id?",
    component: _5afd0fb2,
    name: "explore-company-id",
    children: [{
      path: "key-figures",
      component: _68552447,
      name: "explore-company-id-key-figures"
    }, {
      path: "overview",
      component: _acfae12e,
      name: "explore-company-id-overview"
    }, {
      path: "ownership",
      component: _74f9af56,
      name: "explore-company-id-ownership"
    }, {
      path: "people",
      component: _6f23723f,
      name: "explore-company-id-people"
    }, {
      path: "portfolio",
      component: _503936ee,
      name: "explore-company-id-portfolio"
    }, {
      path: "transactions",
      component: _5ddff5c5,
      name: "explore-company-id-transactions"
    }]
  }, {
    path: "/explore/person/:id?",
    component: _57c37866,
    children: [{
      path: "",
      component: _4cd19a69,
      name: "explore-person-id"
    }, {
      path: "overview",
      component: _b6a1af96,
      name: "explore-person-id-overview"
    }, {
      path: "ownership",
      component: _2fe9a909,
      name: "explore-person-id-ownership"
    }, {
      path: "relations",
      component: _2a57acde,
      name: "explore-person-id-relations"
    }, {
      path: "transactions",
      component: _4c3b4891,
      name: "explore-person-id-transactions"
    }]
  }, {
    path: "/explore/property/:id?",
    component: _5336ff66,
    children: [{
      path: "",
      component: _0f43e169,
      name: "explore-property-id"
    }, {
      path: "buildings",
      component: _bff9164e,
      name: "explore-property-id-buildings"
    }, {
      path: "commercial",
      component: _7b39c986,
      name: "explore-property-id-commercial"
    }, {
      path: "commercial-rent",
      component: _2b28e4f6,
      name: "explore-property-id-commercial-rent"
    }, {
      path: "constructioncases",
      component: _7a018aec,
      name: "explore-property-id-constructioncases"
    }, {
      path: "documents",
      component: _a150ffdc,
      name: "explore-property-id-documents"
    }, {
      path: "easements",
      component: _a2f0f37e,
      name: "explore-property-id-easements"
    }, {
      path: "mortgages",
      component: _271d91f1,
      name: "explore-property-id-mortgages"
    }, {
      path: "overview",
      component: _3e467d96,
      name: "explore-property-id-overview"
    }, {
      path: "owners",
      component: _48805148,
      name: "explore-property-id-owners"
    }, {
      path: "rental-levels",
      component: _763d0812,
      name: "explore-property-id-rental-levels"
    }, {
      path: "tenants",
      component: _25aa6ce3,
      name: "explore-property-id-tenants"
    }, {
      path: "transactions",
      component: _15f46191,
      name: "explore-property-id-transactions"
    }, {
      path: "rental-levels/PageLejedata",
      component: _4232335d,
      name: "explore-property-id-rental-levels-PageLejedata"
    }]
  }, {
    path: "/explore/reference-properties/:id?",
    component: _5d5cc986,
    name: "explore-reference-properties-id"
  }, {
    path: "/portfolio/property/:propertyId?",
    component: _55afa106,
    name: "portfolio-property-propertyId",
    children: [{
      path: "balance",
      component: _0fda0c96,
      name: "portfolio-property-propertyId-balance"
    }, {
      path: "capexes",
      component: _5727e726,
      name: "portfolio-property-propertyId-capexes"
    }, {
      path: "opexes",
      component: _2c9af11e,
      name: "portfolio-property-propertyId-opexes"
    }, {
      path: "overview",
      component: _5718ab95,
      name: "portfolio-property-propertyId-overview"
    }, {
      path: "sales",
      component: _e29672f4,
      name: "portfolio-property-propertyId-sales"
    }, {
      path: "units",
      component: _628e7529,
      name: "portfolio-property-propertyId-units"
    }, {
      path: "valuations",
      component: _eae47da4,
      name: "portfolio-property-propertyId-valuations"
    }]
  }, {
    path: "/portfolio/tenancy/:tenancyId?",
    component: _2314873c,
    name: "portfolio-tenancy-tenancyId",
    children: [{
      path: "capexes",
      component: _788edc23,
      name: "portfolio-tenancy-tenancyId-capexes"
    }, {
      path: "overview",
      component: _374fd29f,
      name: "portfolio-tenancy-tenancyId-overview"
    }, {
      path: "sales",
      component: _6d940bbc,
      name: "portfolio-tenancy-tenancyId-sales"
    }, {
      path: "tenant",
      component: _123a1b50,
      name: "portfolio-tenancy-tenancyId-tenant"
    }, {
      path: "valuations",
      component: _39974db8,
      name: "portfolio-tenancy-tenancyId-valuations"
    }]
  }, {
    path: "/download/packaged/:id?/:filename?",
    component: _1276d666,
    name: "download-packaged-id-filename"
  }, {
    path: "/portfolio/:portfolioId",
    component: _0126f1d6,
    name: "portfolio-portfolioId",
    children: [{
      path: "balance",
      component: _65e7ac65,
      name: "portfolio-portfolioId-balance"
    }, {
      path: "companies",
      component: _cd2fedb8,
      name: "portfolio-portfolioId-companies"
    }, {
      path: "opex",
      component: _a4d7bc7e,
      name: "portfolio-portfolioId-opex"
    }, {
      path: "overview",
      component: _7a81f4b4,
      name: "portfolio-portfolioId-overview"
    }, {
      path: "properties",
      component: _45b6f860,
      name: "portfolio-portfolioId-properties"
    }, {
      path: "tenancies",
      component: _ee7f69b2,
      name: "portfolio-portfolioId-tenancies"
    }, {
      path: "composables/useQueryWithPeriod",
      component: _63f91aca,
      name: "portfolio-portfolioId-composables-useQueryWithPeriod"
    }, {
      path: "overview/index.overview",
      component: _67aea371,
      name: "portfolio-portfolioId-overview-index.overview"
    }]
  }, {
    path: "/reset-password/:id?",
    component: _4179936e,
    name: "reset-password-id"
  }, {
    path: "/template-builder/:id?",
    component: _2f84a3dd,
    name: "template-builder-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
