import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.link.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import { arrayMutateToggleValue, hasValue } from "~/helpers/common-helpers";
import { getSetValue } from "~/helpers/portfolio/portfolio-category-helpers";
export var useDataTableRows = function useDataTableRows(headers, data, tableContext, getRowKey, options) {
  var _options$selection, _options$onSelection, _options$copy;
  var selection = (_options$selection = options === null || options === void 0 ? void 0 : options.selection) !== null && _options$selection !== void 0 ? _options$selection : options !== null && options !== void 0 && options.checkboxColumn ? ref([]) : undefined;
  var onSelection = (_options$onSelection = options === null || options === void 0 ? void 0 : options.onSelection) !== null && _options$onSelection !== void 0 ? _options$onSelection : options !== null && options !== void 0 && options.checkboxColumn ? function (newSelection) {
    return selection.value = newSelection;
  } : undefined;
  var hasCheckboxColumn = selection && onSelection;
  var headersMultiple = computed(function () {
    var newHeaders = headers.value.every(function (h) {
      return "value" in h;
    }) ? headers.value.map(function (h) {
      return {
        id: h.id,
        headers: {
          value: [h]
        }
      };
    }) : headers.value.slice();
    if (hasCheckboxColumn) {
      newHeaders.unshift({
        id: "options_checkbox",
        headers: {
          value: [{
            id: "checkbox",
            value: function value() {
              return "";
            },
            labelKey: "NONE",
            dataTableCellOptions: {
              renderAs: "checkbox_selection",
              onChange: function onChange(row) {
                var key = getRowKey(row);
                var newSelection = arrayMutateToggleValue(selection.value.slice(), key);
                onSelection(newSelection);
              },
              checked: function checked(row) {
                return computed(function () {
                  return selection.value.includes(getRowKey(row));
                });
              }
            }
          }]
        }
      });
    }
    return newHeaders;
  });
  var onRowClick = options === null || options === void 0 ? void 0 : options.onRowClick;
  var onRowClickEdit = options === null || options === void 0 ? void 0 : options.onRowClickEdit;
  var onRowClickDelete = options === null || options === void 0 ? void 0 : options.onRowClickDelete;
  var hoverEffectStateActive = options === null || options === void 0 ? void 0 : options.hoverEffectStateActive;
  var totals = options === null || options === void 0 ? void 0 : options.totals;
  var optionsGetCopyColorRef = options === null || options === void 0 ? void 0 : (_options$copy = options.copy) === null || _options$copy === void 0 ? void 0 : _options$copy.color;
  var t = useTranslator();
  var tUnit = useTranslateUnit();
  var getDataCellContent = function getDataCellContent(row, options, header) {
    var _header$dataTableCell, _header$dataTableCell2, _header$dataTableCell3;
    var cellValue = header.value(row);
    if (options.renderAs === "slot") return {
      type: "slot"
    };else if (options.renderAs === "checkbox") return {
      type: "checkbox",
      onSave: function onSave(v) {
        return options.onSave(v, row, header);
      },
      value: Boolean(cellValue)
    };else if (options.renderAs === "checkbox_selection") {
      var checked = options.checked(row);
      return {
        type: "checkbox_selection",
        checked: computed({
          get: function get() {
            return checked.value;
          },
          set: function set() {
            return options.onChange(row);
          }
        })
      };
    } else if (options.renderAs === "dropdown") return {
      type: "dropdown",
      onSave: function onSave(v) {
        return options.onSave(v, row, header);
      },
      value: cellValue,
      options: options.options
    };
    var filter = (_header$dataTableCell = header.dataTableCellOptions) === null || _header$dataTableCell === void 0 ? void 0 : _header$dataTableCell.filter;
    var value = filter ? filter(cellValue, row, tableContext.value) : cellValue;
    var getCopyColorRef = "copyColor" in options && options.copyColor ? options.copyColor : optionsGetCopyColorRef;
    var color = getCopyColorRef ? getCopyColorRef(row, header) : undefined;
    var cellContent = {
      type: "copy",
      copy: {
        value: value,
        type: (_header$dataTableCell2 = (_header$dataTableCell3 = header.dataTableCellOptions) === null || _header$dataTableCell3 === void 0 ? void 0 : _header$dataTableCell3.copyType) !== null && _header$dataTableCell2 !== void 0 ? _header$dataTableCell2 : "body",
        size: "medium",
        color: color !== null && color !== void 0 ? color : "--color-black-900"
      }
    };
    switch (options.renderAs) {
      case "label":
        cellContent.label = {
          color: options.labelColor(row)
        };
        break;
      case "link":
        cellContent.copy.color = color !== null && color !== void 0 ? color : "--color-purple-900";
        cellContent.link = {
          navigate: function navigate() {
            return options.navigate(row);
          }
        };
        break;
      case "editable":
        cellContent.copy.value = cellValue;
        cellContent.edit = {
          filter: function filter(v) {
            return options.filter(v, row, tableContext.value);
          },
          type: options.type,
          onSave: function onSave(value) {
            return options.onSave(value, row, header);
          }
        };
        break;
    }
    return cellContent;
  };
  var totalWidthFractions = headersMultiple.value.map(function (h) {
    return h.headers.value.filter(function (h) {
      return hasValue(h.widthFractions);
    });
  }).flat().reduce(function (a, b) {
    var _b$widthFractions;
    return a + ((_b$widthFractions = b.widthFractions) !== null && _b$widthFractions !== void 0 ? _b$widthFractions : 0);
  }, 0);
  return computed(function () {
    var headerRowsMap = new Map();
    var titleRowExists = headersMultiple.value.some(function (h) {
      return !!h.headers.title;
    });
    headersMultiple.value.forEach(function (configMultiple, index) {
      var title = configMultiple.headers.title;
      var hasTitleRow = hasValue(title);
      var rowSpan = !titleRowExists || hasTitleRow ? 1 : 2;
      var values = configMultiple.headers.value;
      var colSpan = values.length;
      var addConfig = function addConfig(id, config) {
        var _config$label;
        var group = getSetValue(id, headerRowsMap, function () {
          return {
            id: configMultiple.id,
            cells: []
          };
        });
        group.cells.push({
          sortDirection: config.sortDirection,
          sortKey: config.sortKey,
          sortOverrideNullOrder: config.sortOverrideNullOrder,
          sortRelation: config.sortRelation,
          id: "".concat(configMultiple.id, "_").concat(id, "_").concat(config.id),
          colSpan: id === "value" ? 1 : colSpan,
          rowSpan: rowSpan,
          copy: configMultiple.id === "options_checkbox" ? {
            type: "checkbox_selection",
            checked: computed({
              get: function get() {
                return selection.value.length === data.value.length;
              },
              set: function set(v) {
                return selection.value = v ? data.value.map(getRowKey) : [];
              }
            })
          } : {
            type: "copy",
            value: (_config$label = config.label) !== null && _config$label !== void 0 ? _config$label : t(config.labelKey, {
              area: tUnit(tableContext.value.areaUnit)
            }),
            textAlignEnd: config.textAlignEnd,
            width: config.explodeWidth ? hasValue(config.widthFractions) ? "".concat(Math.round(config.widthFractions / totalWidthFractions), "%") : "100%" : "auto",
            minWidth: config.minWidth,
            maxWidth: config.maxWidth,
            tooltip: config.tooltip
          }
        });
      };
      if (hasTitleRow) {
        addConfig("title", title);
      }
      for (var v = 0; v < values.length; v++) {
        var key = hasTitleRow ? "value" : "title";
        addConfig(key, values[v]);
      }
    });
    return {
      rows: data.value.map(function (row) {
        return {
          key: getRowKey(row),
          onClick: onRowClick ? function () {
            return onRowClick(row);
          } : undefined,
          onClickEdit: onRowClickEdit ? function () {
            return onRowClickEdit(row);
          } : undefined,
          onClickDelete: onRowClickDelete ? function () {
            return onRowClickDelete(row);
          } : undefined,
          totals: totals ? totals(row) : false,
          hoverEffectStateActive: hoverEffectStateActive ? hoverEffectStateActive(row) : undefined,
          cells: headersMultiple.value.map(function (configMultiple) {
            return configMultiple.headers.value.map(function (header) {
              var _header$dataTableCell4, _header$dataTableCell5, _header$dataTableCell6;
              var options = (_header$dataTableCell4 = header.dataTableCellOptions) !== null && _header$dataTableCell4 !== void 0 ? _header$dataTableCell4 : {};
              var onCellClick = header.onClick;
              var cellColor = (_header$dataTableCell5 = header.dataTableCellOptions) === null || _header$dataTableCell5 === void 0 ? void 0 : _header$dataTableCell5.cellColor;
              var tooltip = (_header$dataTableCell6 = header.dataTableCellOptions) === null || _header$dataTableCell6 === void 0 ? void 0 : _header$dataTableCell6.tooltip;
              return {
                key: configMultiple.headers.value.length > 1 ? "".concat(configMultiple.id, "_").concat(header.id) : configMultiple.id,
                onClick: onCellClick ? function () {
                  return onCellClick(row);
                } : undefined,
                content: getDataCellContent(row, options, header),
                textAlignEnd: header.textAlignEnd,
                cellColor: cellColor ? cellColor(row) : undefined,
                tooltip: tooltip ? tooltip(row) : undefined
              };
            });
          }).flat()
        };
      }),
      headers: Array.from(headerRowsMap.entries()).sort(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
          a = _ref2[0];
        return a === "value" ? 1 : -1;
      }).map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
          k = _ref4[0],
          row = _ref4[1];
        return row;
      }),
      getSlotName: function getSlotName(row, headerId) {
        return getRowKey(row) + headerId;
      }
    };
  });
};