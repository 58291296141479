import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find-index.js";
import "core-js/modules/es6.array.find.js";
import { MetricPeriodType } from "~/graphql/generated/graphql";
import { fitDate, fitDateString, getAccountAggregationKey } from "./portfolio-balance-helpers";
import moment from "moment";
import { hasValue } from "../common-helpers";
export var getSetValue = function getSetValue(key, map, create) {
  var value = map.get(key);
  if (!value) {
    value = create();
    map.set(key, value);
  }
  return value;
};
export var getBudgetCategoryMap = function getBudgetCategoryMap(budgets, periodType) {
  var accounts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : new Map();
  var map = new Map();
  var getIncomeCompleted = function getIncomeCompleted(periodMs, categoryId, accountKey) {
    var _accounts$get, _accounts$get$get;
    if (accounts.size === 0) return 0;
    var value = (_accounts$get = accounts.get(periodMs)) === null || _accounts$get === void 0 ? void 0 : (_accounts$get$get = _accounts$get.get(categoryId)) === null || _accounts$get$get === void 0 ? void 0 : _accounts$get$get.get(accountKey);
    return value;
  };
  var _loop = function _loop() {
    var _budget$assetManageme, _budget$assetManageme2;
    var budget = budgets[i];
    var budgetRecords = (_budget$assetManageme = (_budget$assetManageme2 = budget.assetManagementBudgetRecords) === null || _budget$assetManageme2 === void 0 ? void 0 : _budget$assetManageme2.items) !== null && _budget$assetManageme !== void 0 ? _budget$assetManageme : [];
    for (var j = 0; j < budgetRecords.length; j++) {
      var _record$assetManageme, _record$actualCostWit;
      var record = budgetRecords[j];
      var accountKey = getAccountAggregationKey(record.assetManagementAccount);
      var categoryId = (_record$assetManageme = record.assetManagementAccount) === null || _record$assetManageme === void 0 ? void 0 : _record$assetManageme.categoryId;
      if (!hasValue(accountKey) || !hasValue(categoryId)) continue;
      var period = fitDateString(record.entryDate, periodType);
      var periodGroup = getSetValue(period.valueOf(), map, function () {
        return new Map();
      });
      var periodBudgetGroup = getSetValue(budget.id, periodGroup, function () {
        var _accounts$get$entries, _accounts$get2, _budget$name;
        var budgetPeriodMS = fitDate(moment.utc(budget.validFrom).subtract(1, "month"), MetricPeriodType.Monthly).valueOf();
        var budgetCategories = Array.from((_accounts$get$entries = (_accounts$get2 = accounts.get(budgetPeriodMS)) === null || _accounts$get2 === void 0 ? void 0 : _accounts$get2.entries()) !== null && _accounts$get$entries !== void 0 ? _accounts$get$entries : []).map(function (_ref) {
          var _accountMap$entries;
          var _ref2 = _slicedToArray(_ref, 2),
            categoryId = _ref2[0],
            accountMap = _ref2[1];
          var categoryAccounts = Array.from((_accountMap$entries = accountMap.entries()) !== null && _accountMap$entries !== void 0 ? _accountMap$entries : []).map(function (_ref3) {
            var _ref4 = _slicedToArray(_ref3, 1),
              accountKey = _ref4[0];
            return [accountKey, {
              value: 0,
              total: 0,
              difference: 0
            }];
          });
          return [categoryId, new Map(categoryAccounts)];
        });
        var categories = new Map(budgetCategories);
        return {
          categories: categories,
          validFrom: budget.validFrom,
          id: budget.id,
          description: (_budget$name = budget.name) !== null && _budget$name !== void 0 ? _budget$name : budget.description
        };
      });
      var periodBudgetCategoryGroup = getSetValue(categoryId, periodBudgetGroup.categories, function () {
        return new Map();
      });
      var periodBudgetCategoryAccountsGroup = getSetValue(accountKey, periodBudgetCategoryGroup, function () {
        return {
          value: 0,
          total: 0,
          difference: 0
        };
      });
      var actual = (_record$actualCostWit = record.actualCostWithVat) !== null && _record$actualCostWit !== void 0 ? _record$actualCostWit : 0;
      periodBudgetCategoryAccountsGroup.value += actual;
      var recordSpecificPeriod = fitDateString(record.entryDate, MetricPeriodType.Monthly);
      var completed = getIncomeCompleted(recordSpecificPeriod.valueOf(), categoryId, accountKey);
      if (hasValue(completed)) completed *= -1;
      periodBudgetCategoryAccountsGroup.difference += actual - (completed || actual);
    }
    var budgetPeriods = Array.from(map.keys()).sort(function (a, b) {
      return a - b;
    });
    var getBalanceStartingTotal = function getBalanceStartingTotal(budget, categoryId, accountKey) {
      var _accounts$get3, _accounts$get3$get;
      if (accounts.size === 0) return 0;
      var budgetPrePeriodMS = fitDate(moment.utc(budget.validFrom).subtract(1, "month"), MetricPeriodType.Monthly).valueOf();
      var value = (_accounts$get3 = accounts.get(budgetPrePeriodMS)) === null || _accounts$get3 === void 0 ? void 0 : (_accounts$get3$get = _accounts$get3.get(categoryId)) === null || _accounts$get3$get === void 0 ? void 0 : _accounts$get3$get.get(accountKey);
      return value;
    };
    var _loop2 = function _loop2() {
      var _map$get;
      var period = budgetPeriods[_i];
      var prevPeriod = budgetPeriods[_i - 1];
      (_map$get = map.get(period)) === null || _map$get === void 0 ? void 0 : _map$get.forEach(function (budget, budgetId) {
        budget.categories.forEach(function (accounts, categoryId) {
          accounts.forEach(function (account, accountKey) {
            var _map$get2, _map$get2$get, _map$get2$get$categor, _ref5, _prevAccount$total;
            var prevAccount = (_map$get2 = map.get(prevPeriod)) === null || _map$get2 === void 0 ? void 0 : (_map$get2$get = _map$get2.get(budgetId)) === null || _map$get2$get === void 0 ? void 0 : (_map$get2$get$categor = _map$get2$get.categories.get(categoryId)) === null || _map$get2$get$categor === void 0 ? void 0 : _map$get2$get$categor.get(accountKey);
            var prevValue = (_ref5 = (_prevAccount$total = prevAccount === null || prevAccount === void 0 ? void 0 : prevAccount.total) !== null && _prevAccount$total !== void 0 ? _prevAccount$total : getBalanceStartingTotal(budget, categoryId, accountKey)) !== null && _ref5 !== void 0 ? _ref5 : 0;
            account.total = prevValue + account.value;
          });
        });
      });
    };
    for (var _i = 0; _i < budgetPeriods.length; _i++) {
      _loop2();
    }
  };
  for (var i = 0; i < budgets.length; i++) {
    _loop();
  }
  return map;
};
export var getCategoriesVertical = function getCategoriesVertical(data, columns, budgetRecordsMap, periodType, type) {
  //Caching a map of children here, for lookup
  var childrenMap = new Map();
  var categoryIds = new Set();
  var periods = new Set();
  for (var i = 0; i < data.length; i++) {
    var item = data[i];
    var group = getSetValue(item.parentCategoryId, childrenMap, function () {
      return [];
    });
    group.push(item);
    categoryIds.add(item.categoryId);
    periods.add(item.period);
  }
  var columnIndices = new Map();
  for (var _i2 = 0; _i2 < columns.length; _i2++) {
    columnIndices.set(columns[_i2].valueOf(), _i2);
  }
  var getChildren = function getChildren(parentId, parentValues) {
    var _childrenMap$get;
    var children = (_childrenMap$get = childrenMap.get(parentId)) !== null && _childrenMap$get !== void 0 ? _childrenMap$get : [];
    var groups = new Map();
    var createGroup = function createGroup(item) {
      var values = getEmptyValues();
      var children = getChildren(item.categoryId, values);
      var isBlueprint = children.every(function (c) {
        return c.isBlueprint;
      });
      return {
        id: item.categoryId,
        name: item.categoryName,
        values: values,
        expanded: item.categoryLevel < 2 && !!children.length,
        level: item.categoryLevel,
        children: children,
        hashes: [],
        isBlueprint: isBlueprint,
        currency: item.currency
      };
    };
    var _loop3 = function _loop3(_i3) {
      var child = children[_i3];
      var group = getSetValue(children[_i3].categoryId, groups, function () {
        return createGroup(children[_i3]);
      });
      if (!child.isBlueprint) group.isBlueprint = false;
      if (child.hash) group.hashes.push(child.hash);
      var period = fitDateString(child.period, periodType);
      var periodIndex = columnIndices.get(period.valueOf());
      var groupValue = group.values[periodIndex];
      var parentValue = parentValues[periodIndex];
      if (!hasValue(groupValue)) return 1; // continue

      /**Blueprint colonne / kategori niveau */
      if (!child.isBlueprint) groupValue.isBlueprint = false;
      if (!groupValue.isBlueprint) parentValue.isBlueprint = false;
      groupValue.totalCostWithVat += child.totalCostWithVat;
      if (hasValue(parentValue)) {
        parentValue.totalCostWithVat += groupValue.totalCostWithVat;
      }
    };
    for (var _i3 = 0; _i3 < children.length; _i3++) {
      if (_loop3(_i3)) continue;
    }
    var rows = Array.from(groups.values());
    for (var _i4 = 0; _i4 < columns.length; _i4++) {
      var _loop4 = function _loop4() {
        var period = columns[_i4];
        var row = rows[j];
        var groupValue = row.values[_i4];
        var parentValue = parentValues[_i4];
        var budgets = budgetRecordsMap.get(period.valueOf());
        if (budgets) {
          budgets.forEach(function (budget) {
            var _budget$categories$ge, _budget$categories$ge2;
            var accounts = Array.from((_budget$categories$ge = (_budget$categories$ge2 = budget.categories.get(row.id)) === null || _budget$categories$ge2 === void 0 ? void 0 : _budget$categories$ge2.values()) !== null && _budget$categories$ge !== void 0 ? _budget$categories$ge : []);
            if (!accounts.length) return;
            var budgetValueSum = accounts.reduce(function (a, b) {
              return a + b.value;
            }, 0);
            var budgetTotalSum = accounts.reduce(function (a, b) {
              return a + b.total;
            }, 0);
            var budgetDifferenceSum = accounts.reduce(function (a, b) {
              return a + b.difference;
            }, 0);
            if (!groupValue.budgets[budget.id]) groupValue.budgets[budget.id] = {
              total: 0,
              value: 0,
              difference: 0
            };
            groupValue.budgets[budget.id].total += budgetTotalSum * (type === "results" ? -1 : 1);
            groupValue.budgets[budget.id].value += budgetValueSum * (type === "results" ? -1 : 1);
            groupValue.budgets[budget.id].difference += budgetDifferenceSum * (type === "results" ? -1 : 1);
            row.isBlueprint = false;
          });
        }
        for (var budgetKey in groupValue.budgets) {
          if (!parentValue.budgets[budgetKey]) parentValue.budgets[budgetKey] = {
            total: 0,
            value: 0,
            difference: 0
          };
          parentValue.budgets[budgetKey].total += groupValue.budgets[budgetKey].total;
          parentValue.budgets[budgetKey].value += groupValue.budgets[budgetKey].value;
          parentValue.budgets[budgetKey].difference += groupValue.budgets[budgetKey].difference;
        }
      };
      for (var j = 0; j < rows.length; j++) {
        _loop4();
      }
    }
    return rows;
  };
  var getEmptyValues = function getEmptyValues() {
    return columns.map(function () {
      return {
        totalCostWithVat: 0,
        change: 0,
        changePercentage: 0,
        isBlueprint: true,
        isForecast: false,
        budgets: {},
        budgetForecasts: {},
        type: type
      };
    });
  };
  var orphanIds = Array.from(childrenMap.keys()).filter(function (id) {
    return !categoryIds.has(id);
  });
  var categories = _toConsumableArray(orphanIds.map(function (id) {
    return getChildren(id !== null && id !== void 0 ? id : null, getEmptyValues()).map(function (c) {
      return reactive(c);
    });
  })).flat();
  return categories;
};
export var mutateRowsHorizontal = function mutateRowsHorizontal(rows, columns, periodType, showForecasts, type) {
  var currentPeriod = fitDate(moment.utc(), periodType);

  /**
   * NOTE Sven: We manually calculate change temporarily while waiting for a backend fix
   */
  for (var i = 0; i < rows.length; i++) {
    var group = rows[i];
    for (var j = group.values.length - 1; j >= 0; j--) {
      var period = columns[j];
      var periodIncomplete = period.isAfter(currentPeriod);
      var cell = group.values[j];
      var prevCell = group.values[j + 1];
      if (showForecasts && type === "balance" && periodIncomplete) {
        /**Calculating forecast */
        for (var budgetId in cell.budgets) {
          var _prevCell$budgetForec;
          var budgetValue = cell.budgets[budgetId].value;
          var prevValue = (_prevCell$budgetForec = prevCell === null || prevCell === void 0 ? void 0 : prevCell.budgetForecasts[budgetId]) !== null && _prevCell$budgetForec !== void 0 ? _prevCell$budgetForec : prevCell ? prevCell.totalCostWithVat : cell.totalCostWithVat;
          cell.budgetForecasts[budgetId] = prevValue + budgetValue;
        }
      }
      if (showForecasts && type === "results" && periodIncomplete) {
        for (var _budgetId in cell.budgets) {
          var _budgetValue = cell.budgets[_budgetId].value;
          var difference = cell.budgets[_budgetId].difference;
          cell.budgetForecasts[_budgetId] = _budgetValue - difference;
        }
      }
      if (prevCell) {
        var value = cell.totalCostWithVat;
        var _prevValue = prevCell.totalCostWithVat;
        var change = value - _prevValue;
        var bottom = Math.round(_prevValue * 100) / 100;
        var top = change;
        cell.change = change;
        cell.changePercentage = top * 100 / Math.abs(bottom);
      } else {
        cell.change = NaN;
        cell.changePercentage = NaN;
      }
    }
  }
};
export var getCategories = function getCategories(data, columns, budgetRecordsMap, periodType, showForecasts, type) {
  var rows = getCategoriesVertical(data, columns, budgetRecordsMap, periodType, type);
  mutateRowsHorizontal(rows, columns, periodType, showForecasts, type);
  return rows;
};
export var getAccountsVertical = function getAccountsVertical(accounts, columns, budgetRecordsMap, periodType, type) {
  var map = new Map();
  var getSetAccount = function getSetAccount(account) {
    return getSetValue(getAccountAggregationKey(account), map, function () {
      return {
        id: account.accountId,
        categoryId: account.categoryId,
        accountNumber: account.accountNumber,
        name: "".concat(account.accountNumber, " ").concat(account.accountName),
        values: columns.map(function () {
          return {
            totalCostWithVat: 0,
            change: 0,
            changePercentage: 0,
            budgets: {},
            budgetForecasts: {},
            isBlueprint: true,
            isForecast: false,
            type: type
          };
        }),
        type: type
      };
    });
  };
  var _loop5 = function _loop5() {
    var account = accounts[i];
    var group = getSetAccount(account);
    var periodMs = fitDateString(account.period, periodType).valueOf();
    var periodIndex = columns.findIndex(function (c) {
      return c.valueOf() === periodMs;
    });
    var groupValue = group.values[periodIndex];
    if (!groupValue) return 1; // continue
    groupValue.totalCostWithVat += account.totalCostWithVat;
  };
  for (var i = 0; i < accounts.length; i++) {
    if (_loop5()) continue;
  }

  /**NOTE Sven: Due to holes in the account data, it will not hit all periods above.
   * This means some column groups are not created, so we have to go through all of them here */
  var _loop6 = function _loop6(_i5) {
    var period = columns[_i5];
    var budgets = budgetRecordsMap.get(period.valueOf());
    if (!budgets) return 1; // continue
    budgets.forEach(function (budget) {
      budget.categories.forEach(function (category) {
        category.forEach(function (value, accountKey) {
          var account = accounts.find(function (acc) {
            return getAccountAggregationKey(acc) === accountKey;
          });
          if (!account) return;
          var groupValue = getSetAccount(account);
          if (!groupValue.values[_i5].budgets[budget.id]) groupValue.values[_i5].budgets[budget.id] = {
            total: 0,
            value: 0,
            difference: 0
          };
          groupValue.values[_i5].budgets[budget.id].total += value.total * (type === "results" ? -1 : 1);
          groupValue.values[_i5].budgets[budget.id].value += value.value * (type === "results" ? -1 : 1);
          groupValue.values[_i5].budgets[budget.id].difference += value.difference * (type === "results" ? -1 : 1);
        });
      });
    });
  };
  for (var _i5 = 0; _i5 < columns.length; _i5++) {
    if (_loop6(_i5)) continue;
  }
  return Array.from(map.values());
};
export var getAccounts = function getAccounts(data, columns, budgetRecordsMap, periodType, showForecasts, type) {
  var rows = getAccountsVertical(data, columns, budgetRecordsMap, periodType, type);
  mutateRowsHorizontal(rows, columns, periodType, showForecasts, type);
  return rows;
};