import { render, staticRenderFns } from "./WidgetLayout.vue?vue&type=template&id=45efa87d"
import script from "./WidgetLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./WidgetLayout.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./WidgetLayout.vue?vue&type=style&index=0&id=45efa87d&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports