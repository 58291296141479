import moment from "moment";
import { type AssetManagementSale, AssetManagementTenancyStatus, AssetManagementPropertyStatus } from "~/graphql/generated/graphql";
import { type TranslationKey } from "../common-helpers";

export type SalesFinance = {
  spaDate?: Nullable<string>;
  repaymentActual?: Nullable<number>;
  bondRepayment?: Nullable<number>;
  lenderBrokerageFee?: Nullable<number>;
  lenderCharge?: Nullable<number>;
  bankChargesWithInterest?: Nullable<number>;
  actualSalesPrice?: Nullable<number>;
  onlineFrom?: Nullable<string>;
  updatedAt?: Nullable<string>;
  takeOverDate?: Nullable<string>;
};

export const SALES_STATUS = {
  REMOVED: "REMOVED",
  NOT_FOR_SALE: "NOT_FOR_SALE",
  PREPARING_FOR_SALE: "PREPARING_FOR_SALE",
  FOR_SALE: "FOR_SALE",
  OFFER_ACCEPTED: "OFFER_ACCEPTED",
  SPA_BINDING: "SPA_BINDING",
  HANDED_OVER: "HANDED_OVER",
  COMPLETED: "COMPLETED",
};

export const getSalesStatusText = (status: Nullable<AssetManagementTenancyStatus | AssetManagementPropertyStatus>) => {
  switch (status) {
    case SALES_STATUS.REMOVED:
      return "Removed";
    case SALES_STATUS.NOT_FOR_SALE:
      return "Not for sale";
    case SALES_STATUS.PREPARING_FOR_SALE:
      return "Preparing for sale";
    case SALES_STATUS.FOR_SALE:
      return "For sale";
    case SALES_STATUS.OFFER_ACCEPTED:
      return "Offer accepted";
    case SALES_STATUS.SPA_BINDING:
      return "SPA binding";
    case SALES_STATUS.HANDED_OVER:
      return "Handed over";
    case SALES_STATUS.COMPLETED:
      return "Completed";
    default:
      return "Unknown";
  }
};

export const getSalesStatusValues = (status: Nullable<AssetManagementTenancyStatus>) => {
  switch (status) {
    case SALES_STATUS.REMOVED:
      return 0;
    case SALES_STATUS.NOT_FOR_SALE:
      return 0;
    case SALES_STATUS.PREPARING_FOR_SALE:
      return 1;
    case SALES_STATUS.FOR_SALE:
      return 2;
    case SALES_STATUS.OFFER_ACCEPTED:
      return 3;
    case SALES_STATUS.SPA_BINDING:
      return 4;
    case SALES_STATUS.HANDED_OVER:
      return 5;
    case SALES_STATUS.COMPLETED:
      return 6;
    default:
      return 0;
  }
};

export const getTranslationKeyForSalesStatus = (status: AssetManagementTenancyStatus): TranslationKey => `PORTFOLIO_SALES_STATE_${status}`;

export const getSalesStatusIndex = (status: Nullable<AssetManagementTenancyStatus | AssetManagementPropertyStatus>) => {
  return Object.values(SALES_STATUS).indexOf(status ?? "");
};

export const getValueForSegmentedBar = (status: Nullable<AssetManagementTenancyStatus | AssetManagementPropertyStatus>) => {
  return getSalesStatusIndex(status);
};

export const getSalesStatusFromIndex = (index: number) => {
  return Object.values(SALES_STATUS)[index] as AssetManagementTenancyStatus | AssetManagementPropertyStatus;
};

export const getLatestSalesFinances = (salesFinances: Nullable<SalesFinance[]>) => {
  if (!salesFinances || salesFinances.length === 0) {
    return null;
  }

  return [...salesFinances].sort((a, b) => {
    return a.updatedAt && b.updatedAt ? (a.updatedAt as string).localeCompare(b.updatedAt as string) : 0;
  })[0];
};

export const getDaysOnMarket = (salesFinance: SalesFinance) => {
  if (!salesFinance || !salesFinance.onlineFrom) {
    return 0;
  }

  if (salesFinance.spaDate != null) {
    return moment(salesFinance.spaDate).diff(moment(salesFinance.onlineFrom), "days");
  } else {
    return moment().diff(moment(salesFinance.onlineFrom), "days");
  }
};

/**
 * Ported from old MIA system
 *
 */
export const getMortgageLoss = (salesFinance: SalesFinance) => {
  if (!salesFinance) {
    return 0;
  }

  let bR = (salesFinance.bondRepayment ?? 0) * 1000000;
  let aR = (salesFinance.repaymentActual ?? 0) * 1000000;

  return (bR - aR) / 1000000;
};

/**
 * Ported from old MIA system
 *
 */
export const getLoanRepaymentAndCosts = (salesFinance: SalesFinance) => {
  if (!salesFinance) {
    return 0;
  }

  let bR = (salesFinance.bondRepayment ?? 0) * 1000000;
  let lBF = (salesFinance.lenderBrokerageFee ?? 0) * 1000000;
  let lC = (salesFinance.lenderCharge ?? 0) * 1000000;
  let bCWI = (salesFinance.bankChargesWithInterest ?? 0) * 1000000;

  return (bR + lBF + lC + bCWI) / 1000000;
};
