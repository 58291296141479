import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { defineStore } from "pinia";
import { useQuery } from "@vue/apollo-composable";
import { OrganizationDocument } from "~/graphql/generated/graphql";
import { useAmplitudeStore } from "./amplitude";
export var useUserStore = defineStore("user", function () {
  var _useAmplitudeStore = useAmplitudeStore(),
    identify = _useAmplitudeStore.identify;
  var user = ref();
  var orgQuery = useQuery(OrganizationDocument, function () {
    return {
      orgId: user.value.orgId
    };
  }, function () {
    return {
      enabled: !!user.value
    };
  });
  var org = computed(function () {
    var _orgQuery$result$valu;
    return (_orgQuery$result$valu = orgQuery.result.value) === null || _orgQuery$result$valu === void 0 ? void 0 : _orgQuery$result$valu.organization;
  });
  watch([org, user], function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      org = _ref2[0],
      user = _ref2[1];
    if (org && user) identify(user, org);
  }, {
    immediate: true
  });
  return {
    user: user,
    org: org
  };
});