import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.string.starts-with.js";
import "core-js/modules/es6.regexp.replace.js";
import { asOperatorField, daysOnMarketValues, iterateFilterCollectionKeys, dateFilterStringMap, irrevocableFilterStringMap } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { Operator, toWritableRef } from "~/helpers/apollo/apollo-utility-helpers";
import { AssetManagementCustomFieldType } from "~/graphql/generated/graphql";
import { filterWithValue } from "~/helpers/common-helpers";
export var useTenanciesUrlParams = function useTenanciesUrlParams() {
  return useUrlParams({
    date: {
      type: "string"
    },
    page: {
      type: "number",
      defaultValue: 1
    },
    pageSize: {
      type: "number",
      defaultValue: 25
    },
    sort: {
      type: "sort"
    },
    daysOnMarket: {
      type: "number"
    },
    salesStatus: {
      type: "stringArray"
    },
    rentStatus: {
      type: "string"
    },
    search: {
      type: "string"
    },
    tenancyTypes: {
      type: "stringArray"
    },
    portfolioIds: {
      type: "stringArray"
    },
    propertyIds: {
      type: "stringArray"
    },
    arrears: {
      type: "number"
    },
    startFrom: {
      type: "string"
    },
    startTo: {
      type: "string"
    },
    propertyAdminStartFrom: {
      type: "string"
    },
    propertyAdminStartTo: {
      type: "string"
    },
    propertyAdminEndFrom: {
      type: "string"
    },
    propertyAdminEndTo: {
      type: "string"
    },
    endFrom: {
      type: "string"
    },
    endTo: {
      type: "string"
    },
    areaFrom: {
      type: "number"
    },
    areaTo: {
      type: "number"
    },
    zipFrom: {
      type: "number"
    },
    zipTo: {
      type: "number"
    },
    irrevocableTenant: {
      type: "stringArray"
    },
    irrevocableLandlord: {
      type: "stringArray"
    },
    rentRegulationPrinciples: {
      type: "stringArray"
    },
    filterId: {
      type: "string"
    },
    assignedTo: {
      type: "stringArray"
    }
  });
};

/**
 * Note Sven: Keeping this to avoid rewrite / testing
 */
export var useTenanciesUrlParamRefs = function useTenanciesUrlParamRefs() {
  var params = useTenanciesUrlParams();
  return {
    daysOnMarket: toWritableRef(params("daysOnMarket")),
    salesStatus: toWritableRef(params("salesStatus")),
    rentStatus: toWritableRef(params("rentStatus")),
    search: toWritableRef(params("search")),
    tenancyTypes: toWritableRef(params("tenancyTypes")),
    portfolioIds: toWritableRef(params("portfolioIds")),
    propertyIds: toWritableRef(params("propertyIds")),
    rentRegulationPrinciples: toWritableRef(params("rentRegulationPrinciples"))
  };
};

/**
 * Takes portfolioId from url path parameter first prio, then from query parameters if first does not exist
 */
export var useTenanciesPortfolioIds = function useTenanciesPortfolioIds(portfolioId) {
  var queryParams = useTenanciesUrlParams();
  var _queryParams = queryParams("portfolioIds"),
    queryPortfolioIds = _queryParams.value;
  return computed(function () {
    return portfolioId ? [portfolioId] : queryPortfolioIds.value;
  });
};
export var useTenanciesPropertyIds = function useTenanciesPropertyIds(propertyId) {
  var queryParams = useTenanciesUrlParams();
  var _queryParams2 = queryParams("propertyIds"),
    queryPropertyIds = _queryParams2.value;
  return computed(function () {
    return propertyId ? [propertyId] : queryPropertyIds.value;
  });
};
var parseRangeFilter = function parseRangeFilter(key, group) {
  var fieldA = asOperatorField(group.matches[0], key);
  var fieldB = asOperatorField(group.matches[1], key);
  var min = (fieldA === null || fieldA === void 0 ? void 0 : fieldA.operator) === Operator.GreaterThanOrEqual ? fieldA.value : (fieldB === null || fieldB === void 0 ? void 0 : fieldB.operator) === Operator.GreaterThanOrEqual ? fieldB.value : undefined;
  var max = (fieldA === null || fieldA === void 0 ? void 0 : fieldA.operator) === Operator.LessThanOrEqual ? fieldA.value : (fieldB === null || fieldB === void 0 ? void 0 : fieldB.operator) === Operator.LessThanOrEqual ? fieldB.value : undefined;
  return [min, max];
};
var mapDateFilterFunc = function mapDateFilterFunc(dateFilterFunc) {
  switch (dateFilterFunc) {
    case dateFilterStringMap.today:
      return "today";
    case dateFilterStringMap.tomorrow:
      return "tomorrow";
    default:
      return dateFilterFunc;
  }
};
var parseOldIrrevocableFilters = function parseOldIrrevocableFilters(key, group) {
  var fieldA = asOperatorField(group.matches[0], key);
  var fieldB = asOperatorField(group.matches[1], key);
  var testOptionKey;
  if (!(fieldA !== null && fieldA !== void 0 && fieldA.operator)) return undefined;
  var testField = function testField(field, filters) {
    return field.operator in filters && filters[field.operator] === field.value;
  };
  for (testOptionKey in irrevocableFilterStringMap) {
    var filters = irrevocableFilterStringMap[testOptionKey];
    var matchA = testField(fieldA, filters);
    var matchB = !(fieldB !== null && fieldB !== void 0 && fieldB.operator) || testField(fieldB, filters);
    if (matchA && matchB) return [testOptionKey];
  }
  return undefined;
};
var parseIrrevocableFilters = function parseIrrevocableFilters(key, groups) {
  var keys = [];
  var testOptionKey;
  var _groups = _slicedToArray(groups, 2),
    groupA = _groups[0],
    groupB = _groups[1];
  var fieldsA = filterWithValue(groupA.matches.map(function (m) {
    return asOperatorField(m, key);
  }));
  var fieldsB = filterWithValue(groupB.matches.map(function (m) {
    return asOperatorField(m, key);
  }));
  var _loop = function _loop() {
    var config = irrevocableFilterStringMap[testOptionKey];
    var testField = function testField(o) {
      return o.operator in config && o.value === config[o.operator];
    };
    if (fieldsA.find(testField) && fieldsB.find(testField)) keys.push(testOptionKey);
  };
  for (testOptionKey in irrevocableFilterStringMap) {
    _loop();
  }
  return keys;
};
export var useTenanciesUrlParamsWithSavedFilter = function useTenanciesUrlParamsWithSavedFilter(customFields) {
  var router = useRouter();
  var route = useRoute();
  var params = useTenanciesUrlParams();
  var keys = ["areaFrom", "areaTo", "daysOnMarket", "endFrom", "endTo", "irrevocableLandlord", "irrevocableTenant", "portfolioIds", "propertyIds", "rentRegulationPrinciples", "rentStatus", "salesStatus", "search", "startFrom", "startTo", "propertyAdminStartFrom", "propertyAdminStartTo", "propertyAdminEndFrom", "propertyAdminEndTo", "tenancyTypes", "zipFrom", "zipTo", "arrears", "sort", "assignedTo"];
  var buildQueryObject = computed(function () {
    return function (collection, sort) {
      var queryObject = {};
      var setUrlParam = function setUrlParam(param, value) {
        var paramValue = param.setValue(value, {
          dry: true
        });
        if (paramValue) queryObject[param.key] = paramValue;
      };
      var keysHandled = new Set();
      if (sort !== null && sort !== void 0 && sort.key) setUrlParam(params("sort"), {
        key: sort.key,
        direction: sort.direction,
        relation: sort.relation
      });
      iterateFilterCollectionKeys(collection, function (key, group, match) {
        var _daysOnMarketValues$f, _from$value, _to$value, _join, _valueObject$value;
        if (keysHandled.has(key)) return;
        switch (key) {
          case "name":
            var field = asOperatorField(match, key);
            setUrlParam(params("search"), field === null || field === void 0 ? void 0 : field.value);
            break;
          case "days_on_market":
            var _parseRangeFilter = parseRangeFilter(key, group),
              _parseRangeFilter2 = _slicedToArray(_parseRangeFilter, 2),
              min = _parseRangeFilter2[0],
              max = _parseRangeFilter2[1];
            var value = (_daysOnMarketValues$f = daysOnMarketValues.find(function (_ref) {
              var dayMax = _ref.dayMax,
                dayMin = _ref.dayMin,
                id = _ref.id;
              return dayMin === min && dayMax === max;
            })) === null || _daysOnMarketValues$f === void 0 ? void 0 : _daysOnMarketValues$f.id;
            setUrlParam(params("daysOnMarket"), value);
            break;
          case "status":
            var salesStatusField = asOperatorField(match, key);
            setUrlParam(params("salesStatus"), salesStatusField === null || salesStatusField === void 0 ? void 0 : salesStatusField.value);
            break;
          case "current_tenant_is_ghost":
            var vacant = match[key] === true ? "vacant" : undefined;
            setUrlParam(params("rentStatus"), vacant);
            break;
          case "ready_for_rental":
            var rerent = match[key] === true ? "rerent" : undefined;
            setUrlParam(params("rentStatus"), rerent);
            break;
          case "tenancy_type":
            var tennacyTypeField = asOperatorField(match, key);
            setUrlParam(params("tenancyTypes"), tennacyTypeField === null || tennacyTypeField === void 0 ? void 0 : tennacyTypeField.value);
            break;
          case "portfolio_id":
            var portfolioIdsField = asOperatorField(match, key);
            setUrlParam(params("portfolioIds"), portfolioIdsField === null || portfolioIdsField === void 0 ? void 0 : portfolioIdsField.value);
            break;
          case "property_id":
            var propertyIdsField = asOperatorField(match, key);
            setUrlParam(params("propertyIds"), propertyIdsField === null || propertyIdsField === void 0 ? void 0 : propertyIdsField.value);
            break;
          case "start_date":
            var _parseRangeFilter3 = parseRangeFilter(key, group),
              _parseRangeFilter4 = _slicedToArray(_parseRangeFilter3, 2),
              startFrom = _parseRangeFilter4[0],
              startTo = _parseRangeFilter4[1];
            setUrlParam(params("startFrom"), mapDateFilterFunc(startFrom));
            setUrlParam(params("startTo"), mapDateFilterFunc(startTo));
            break;
          case "end_date":
            var _parseRangeFilter5 = parseRangeFilter(key, group),
              _parseRangeFilter6 = _slicedToArray(_parseRangeFilter5, 2),
              endFrom = _parseRangeFilter6[0],
              endTo = _parseRangeFilter6[1];
            setUrlParam(params("endFrom"), mapDateFilterFunc(endFrom));
            setUrlParam(params("endTo"), mapDateFilterFunc(endTo));
            break;
          case "area":
            var _parseRangeFilter7 = parseRangeFilter(key, group),
              _parseRangeFilter8 = _slicedToArray(_parseRangeFilter7, 2),
              areaFrom = _parseRangeFilter8[0],
              areaTo = _parseRangeFilter8[1];
            setUrlParam(params("areaFrom"), areaFrom);
            setUrlParam(params("areaTo"), areaTo);
            break;
          case "property_zip_code":
            var _parseRangeFilter9 = parseRangeFilter(key, group),
              _parseRangeFilter10 = _slicedToArray(_parseRangeFilter9, 2),
              zipFrom = _parseRangeFilter10[0],
              zipTo = _parseRangeFilter10[1];
            setUrlParam(params("zipFrom"), zipFrom);
            setUrlParam(params("zipTo"), zipTo);
            break;
          case "property_administration_start_date":
            var _parseRangeFilter11 = parseRangeFilter(key, group),
              _parseRangeFilter12 = _slicedToArray(_parseRangeFilter11, 2),
              propertyStartFrom = _parseRangeFilter12[0],
              propertyStartTo = _parseRangeFilter12[1];
            setUrlParam(params("propertyAdminStartFrom"), mapDateFilterFunc(propertyStartFrom));
            setUrlParam(params("propertyAdminStartTo"), mapDateFilterFunc(propertyStartTo));
            break;
          case "property_administration_end_date":
            var _parseRangeFilter13 = parseRangeFilter(key, group),
              _parseRangeFilter14 = _slicedToArray(_parseRangeFilter13, 2),
              propertyEndFrom = _parseRangeFilter14[0],
              propertyEndTo = _parseRangeFilter14[1];
            setUrlParam(params("propertyAdminEndFrom"), mapDateFilterFunc(propertyEndFrom));
            setUrlParam(params("propertyAdminEndTo"), mapDateFilterFunc(propertyEndTo));
            break;
          case "current_tenant_irrevocable_until_tenant":
            var tenantGroups = [];
            iterateFilterCollectionKeys(collection, function (key, group, match) {
              if (key === "current_tenant_irrevocable_until_tenant") tenantGroups.push(group);
            });
            if (tenantGroups.some(function (g) {
              return !g.or;
            })) {
              setUrlParam(params("irrevocableTenant"), parseOldIrrevocableFilters("current_tenant_irrevocable_until_tenant", tenantGroups[0]));
            } else if (tenantGroups.length === 2) {
              setUrlParam(params("irrevocableTenant"), parseIrrevocableFilters("current_tenant_irrevocable_until_tenant", tenantGroups));
            }
            break;
          case "current_tenant_irrevocable_until_landlord":
            var landlordGroups = [];
            iterateFilterCollectionKeys(collection, function (key, group, match) {
              if (key === "current_tenant_irrevocable_until_landlord") landlordGroups.push(group);
            });
            if (landlordGroups.some(function (g) {
              return !g.or;
            })) {
              setUrlParam(params("irrevocableLandlord"), parseOldIrrevocableFilters("current_tenant_irrevocable_until_landlord", landlordGroups[0]));
            } else if (landlordGroups.length === 2) {
              setUrlParam(params("irrevocableLandlord"), parseIrrevocableFilters("current_tenant_irrevocable_until_landlord", landlordGroups));
            }
            break;
          case "rent_regulation_principle":
            var rentRegulationPrincplesField = asOperatorField(match, key);
            setUrlParam(params("rentRegulationPrinciples"), rentRegulationPrincplesField === null || rentRegulationPrincplesField === void 0 ? void 0 : rentRegulationPrincplesField.value);
            break;
          case "current_tenant_balance":
            var arrearsField = asOperatorField(match, key);
            setUrlParam(params("arrears"), arrearsField === null || arrearsField === void 0 ? void 0 : arrearsField.value);
            break;
          case "assigned_to":
            var assignedToField = asOperatorField(match, key);
            setUrlParam(params("assignedTo"), assignedToField === null || assignedToField === void 0 ? void 0 : assignedToField.value);
            break;
          case "custom_field":
            var customFieldOperatorField = asOperatorField(match, key);
            if (customFieldOperatorField !== null && customFieldOperatorField !== void 0 && customFieldOperatorField.value) {
              var valueObject = customFieldOperatorField === null || customFieldOperatorField === void 0 ? void 0 : customFieldOperatorField.value;
              var id = valueObject.id;
              var customField = customFields.value.find(function (f) {
                return f.id === id;
              });
              if (customField) {
                var urlKey = "c_".concat(id);
                switch (customField.fieldType) {
                  case AssetManagementCustomFieldType.Number:
                    var _parseRangeFilter15 = parseRangeFilter("custom_field", group),
                      _parseRangeFilter16 = _slicedToArray(_parseRangeFilter15, 2),
                      from = _parseRangeFilter16[0],
                      to = _parseRangeFilter16[1];
                    queryObject[urlKey] = "".concat((_from$value = from === null || from === void 0 ? void 0 : from.value) !== null && _from$value !== void 0 ? _from$value : "", "...").concat((_to$value = to === null || to === void 0 ? void 0 : to.value) !== null && _to$value !== void 0 ? _to$value : "");
                    break;
                  case AssetManagementCustomFieldType.Boolean:
                    queryObject[urlKey] = "".concat(valueObject.value);
                    break;
                  case AssetManagementCustomFieldType.Text:
                    queryObject[urlKey] = "".concat(valueObject.value);
                    break;
                  case AssetManagementCustomFieldType.Option:
                    queryObject[urlKey] = "".concat((_join = (_valueObject$value = valueObject.value) === null || _valueObject$value === void 0 ? void 0 : _valueObject$value.join(",")) !== null && _join !== void 0 ? _join : "");
                    break;
                }
              }
            }
            break;
        }
        keysHandled.add(key);
      });
      return queryObject;
    };
  });
  var queriesEqual = computed(function () {
    return function (a, b) {
      for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
        var key = _keys[_i];
        if (a[key] !== b[key]) {
          return false;
        }
      }
      for (var _key in a) {
        if (!_key.startsWith("c_")) continue;
        if (a[_key] !== b[_key]) {
          return false;
        }
      }
      for (var _key2 in b) {
        if (!_key2.startsWith("c_")) continue;
        if (a[_key2] !== b[_key2]) {
          return false;
        }
      }
      return true;
    };
  });
  var bookmark = ref();
  var bookmarkQuery = computed(function () {
    return bookmark.value ? buildQueryObject.value(bookmark.value.collection, bookmark.value.sort) : null;
  });
  var pushedQuery = ref(null);
  var currentQuery = computed(function () {
    var queryObject = {};
    for (var _i2 = 0, _keys2 = keys; _i2 < _keys2.length; _i2++) {
      var key = _keys2[_i2];
      var value = route.query[key];
      if (value) queryObject[key] = value;
    }
    for (var _key3 in route.query) {
      if (!_key3.startsWith("c_")) continue;
      var _value = route.query[_key3];
      if (_value) queryObject[_key3] = _value;
    }
    return queryObject;
  });
  watch(bookmarkQuery, function (query) {
    if (query && (!pushedQuery.value || !queriesEqual.value(query, pushedQuery.value))) {
      setQuery(query);
    }
  }, {
    immediate: true
  });
  var changed = computed(function () {
    if (!bookmarkQuery.value) return false;
    return !queriesEqual.value(currentQuery.value, bookmarkQuery.value);
  });
  var setBookmark = function setBookmark(_ref2) {
    var collection = _ref2.filters,
      sort = _ref2.sort;
    bookmark.value = {
      collection: collection,
      sort: sort !== null && sort !== void 0 ? sort : undefined
    };
  };
  var setQuery = function setQuery(query) {
    pushedQuery.value = query;
    if (queriesEqual.value(query, currentQuery.value)) return;
    var newRouteQuery = _objectSpread({}, route.query);
    for (var _i3 = 0, _keys3 = keys; _i3 < _keys3.length; _i3++) {
      var key = _keys3[_i3];
      var value = query[key];
      if (!value) delete newRouteQuery[key];else newRouteQuery[key] = value;
    }
    for (var _key4 in newRouteQuery) if (_key4.startsWith("c_")) delete newRouteQuery[_key4];
    for (var _key5 in query) {
      if (!_key5.startsWith("c_")) continue;
      var _value2 = query[_key5];
      if (_value2) newRouteQuery[_key5] = _value2;
    }
    router.replace({
      query: newRouteQuery
    });
  };
  var reset = function reset() {
    if (bookmarkQuery.value) setQuery(bookmarkQuery.value);
  };
  return {
    params: params,
    changed: changed,
    setBookmark: setBookmark,
    reset: reset
  };
};