import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.from.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import Vue from "vue";
var freeze = function freeze(object, property, value) {
  Object.defineProperty(object, property, {
    configurable: true,
    get: function get() {
      return value;
    },
    set: function set(v) {
      console.warn("tried to set frozen property ".concat(property, " with ").concat(v));
    }
  });
};
var unfreeze = function unfreeze(object, property) {
  var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  Object.defineProperty(object, property, {
    configurable: true,
    writable: true,
    value: value
  });
};
var component = {
  "abstract": true,
  name: 'Fragment',
  props: {
    name: {
      type: String,
      "default": function _default() {
        return Math.floor(Date.now() * Math.random()).toString(16);
      }
    },
    html: {
      type: String,
      "default": null
    }
  },
  mounted: function mounted() {
    var container = this.$el;
    var parent = container.parentNode;
    container.__isFragment = true;
    container.__isMounted = false;
    var head = document.createComment("fragment#".concat(this.name, "#head"));
    var tail = document.createComment("fragment#".concat(this.name, "#tail"));
    container.__head = head;
    container.__tail = tail;

    // use document fragment to improve efficiency
    var tpl = document.createDocumentFragment();
    tpl.appendChild(head);
    Array.from(container.childNodes).forEach(function (node) {
      // container.appendChild(node, true)
      var notFrChild = !node.hasOwnProperty('__isFragmentChild__');
      tpl.appendChild(node);
      if (notFrChild) {
        freeze(node, 'parentNode', container);
        freeze(node, '__isFragmentChild__', true);
      }
    });
    tpl.appendChild(tail);

    // embed html
    if (this.html) {
      var template = document.createElement('template');
      template.innerHTML = this.html;
      // copy elements over
      Array.from(template.content.childNodes).forEach(function (node) {
        tpl.appendChild(node);
      });
    }
    var next = container.nextSibling;
    parent.insertBefore(tpl, container, true);
    parent.removeChild(container);
    freeze(container, 'parentNode', parent);
    freeze(container, 'nextSibling', next);
    if (next) freeze(next, 'previousSibling', container);
    container.__isMounted = true;
  },
  render: function render(h) {
    var _this = this;
    var children = this.$slots["default"];

    // add fragment attribute on the children
    if (children && children.length) children.forEach(function (child) {
      return child.data = _objectSpread(_objectSpread({}, child.data), {}, {
        attrs: _objectSpread({
          fragment: _this.name
        }, (child.data || {}).attrs)
      });
    });
    return h("div", {
      attrs: {
        fragment: this.name
      }
    }, children);
  }
};
export var Fragment = component;
export var Plugin = {
  install: function install(Vue) {
    var orgRemoveChild = window.Node.prototype.removeChild;
    window.Node.prototype.removeChild = function (node) {
      if (this.__isFragment) {
        if (this.parentNode) {
          var ret = this.parentNode.removeChild(node);
          unfreeze(node, 'parentNode');
          return ret;
        }
      } else if (node.__isFragment && node.__isMounted) {
        while (node.__head.nextSibling !== node.__tail) orgRemoveChild.call(this, node.__head.nextSibling);
        orgRemoveChild.call(this, node.__head);
        orgRemoveChild.call(this, node.__tail);
        var prev = node.__head.previousSibling,
          next = node.__tail.nextSibling;
        if (prev) freeze(prev, 'nextSibling', next);
        if (next) freeze(next, 'previousSibling', prev);
        unfreeze(node, 'parentNode');
        return node;
      } else {
        var _prev = node.previousSibling,
          _next = node.nextSibling;
        var _ret = orgRemoveChild.call(this, node);
        if (_prev) freeze(_prev, 'nextSibling', _next);
        if (_next) freeze(_next, 'previousSibling', _prev);
        return _ret;
      }
    };
    var orgInsertBefore = window.Node.prototype.insertBefore;
    window.Node.prototype.insertBefore = function (node, ref) {
      var inFragment = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var realRef = !!ref && !!ref.__isFragment && !!ref.__isMounted ? ref.__head : ref;
      if (this.__isFragment) {
        var notFrChild = !node.hasOwnProperty('__isFragmentChild__'),
          freezeParent = !inFragment || notFrChild;
        notFrChild && freeze(node, '__isFragmentChild__', true);
        var ret = this.parentNode ? this.parentNode.insertBefore(node, ref) : orgInsertBefore.call(this, node, realRef);
        freezeParent && freeze(node, 'parentNode', this);
        return ret;
      } else if (node.__isFragment && node.__isMounted) {
        if (node === ref) {
          console.error('something must be wrong');
          return;
        }
        freeze(node, 'parentNode', this);
        if (node.previousSibling) freeze(node.previousSibling, 'nextSibling', node.nextSibling);
        if (node.nextSibling) freeze(node.nextSibling, 'previousSibling', node.previousSibling);
        freeze(node, 'nextSibling', ref);
        freeze(node, 'previousSibling', ref.previousSibling);
        if (ref.previousSibling) freeze(ref.previousSibling, 'nextSibling', node);
        freeze(ref, 'previousSibling', node);
        var tpl = document.createDocumentFragment(),
          ele = node.__head;
        while (ele && ele !== node.__tail) {
          tpl.appendChild(ele);
          ele = ele.nextSibling;
        }
        tpl.appendChild(node.__tail);
        orgInsertBefore.call(this, tpl, realRef);
        return node;
      } else {
        return orgInsertBefore.call(this, node, realRef);
      }
    };
    var orgAppendChild = window.Node.prototype.appendChild;
    window.Node.prototype.appendChild = function (node) {
      var inFragment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.__isFragment) {
        if (this.parentNode) {
          var notFrChild = !node.hasOwnProperty('__isFragmentChild__'),
            freezeParent = !inFragment || notFrChild;
          notFrChild && freeze(node, '__isFragmentChild__', true);
          var ret = this.parentNode.insertBefore(node, this.__tail, inFragment);
          freezeParent && freeze(node, 'parentNode', this);
          return ret;
        }
      } else if (node) {
        return orgAppendChild.call(this, node);
      }
    };
    Vue.component('Fragment', component);
  }
};
Vue.use(Plugin);