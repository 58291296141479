import moment from "moment";
import { Currency } from "~/helpers/apollo/types";
import { hasValue } from "~/helpers/common-helpers";

export type FieldDropDownValue<T = any> = { value: T; name: string };

export type FieldType = Field["type"];

export type DropDownFieldType<T = any> = { value: T; name: string };

export interface BaseField<T extends string = any> {
  key: T;
  type: FieldType;
  value?: any;
  label?: string;
  labelInput?: string;
  position?: number;
  errors?: string[];
  placeholder?: string;
  disabled?: boolean;
}

export interface DropDownField<T extends string = any> extends BaseField<T> {
  type: "dropdown";
  value?: DropDownFieldType;
  options: DropDownFieldType[];
}

export interface NumberField<T extends string = any> extends BaseField<T> {
  type: "number";
  value?: number | string;
}

export interface BooleanField<T extends string = any> extends BaseField<T> {
  type: "boolean";
  labelPosition?: "top" | "left" | "right";
  mode?: "dropdown" | "tickbox";
  value?: boolean;
}

export interface TextField<T extends string = any> extends BaseField<T> {
  type: "text" | "multiline";
  characterCountLimit?: number;
  value?: string;
}

export interface DateField<T extends string = any> extends BaseField<T> {
  type: "date";
  value?: string;
}

export interface EnumField<E extends Record<string, string> = Record<string, string>, T extends string = any> extends BaseField<T> {
  type: "enum";
  value?: string;
  enumerable: E;
  dropdownSettings?: {
    getLabel?: (value: E[keyof E]) => string;
    allowUnset?: boolean;
  };
}

export interface CurrencyField<T extends string = any> extends BaseField<T> {
  type: "currency";
  value?: Currency;
}

export type Field<T extends string = any> =
  | EnumField<Record<string, string>, T>
  | DropDownField<T>
  | NumberField<T>
  | TextField<T>
  | BooleanField<T>
  | DateField<T>
  | CurrencyField<T>;

export type NewRecord = { id: undefined; columns: Field[] };

export type ChangedRecord = { id: string; columns: Field[] };
export type UpdatedRecords = {
  newRecords: NewRecord[];
  updatedRecords: ChangedRecord[];
  deletedRecords: ChangedRecord[];
};

export const parseFieldValue = (field: Field) => {
  if (!hasValue(field.value)) {
    switch (field.type) {
      case "enum":
        return field.value || (field.dropdownSettings?.allowUnset ? null : undefined);
      default:
        return field.value;
    }
  }

  switch (field.type) {
    case "boolean":
      return !!field.value;
    case "date":
      return moment(field.value, "YYYY-MM-DD").set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toISOString();
    case "number":
      return parseFloat(field.value as string as string);
    case "dropdown":
      return field.value.value;
    case "currency":
    case "enum":
    default:
      return field.value;
  }
};

export const mapFieldsForInput = <T extends Record<string, any>>(fields: Field[]) => {
  return fields.reduce((acc, curr) => {
    acc[curr.key as keyof T] = parseFieldValue(curr);

    return acc;
  }, <T>{});
};
